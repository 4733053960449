/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box } from '@mui/material'
import { ColDef, ICellRendererParams, RowDragEvent } from 'ag-grid-community'
import { updateOrderOfCourseLessons } from 'api/courses'
import {
  checkLessonCanPublish,
  deleteLesson,
  duplicateLesson,
  getLessonById,
  updateLesson,
} from 'api/lessons'
import columnRenderers, { PLACEHOLDER } from 'components/column-renderers'
import ConfirmPublishModal from 'components/modals/ConfirmPublishModal'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { useLessonCSVField } from 'features/Lesson/hooks/useLessonCSVField'
import { createTagsColumn, useTagsFilterForDraggableTable } from 'features/Tags'
import {
  columnCreatedBy,
  columnUpdatedBy,
  getActionColumn,
  UIContentIcons,
  UIMessageNoRows,
} from 'features/UI'
import { useExportArrayCSV } from 'hooks/useExportArrayCSV'
import { PublishAction, usePublishModalControl } from 'hooks/usePublishModalControl'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router'
import { routes } from 'routes/routes'
import { LessonShort } from 'types'
import { withIdParam } from 'utils'
import { useLessonMove } from '../../hooks/useLessonMove'
import { LessonModalDuplicate } from '../LessonModalDuplicate/LessonModalDuplicate'

export const LessonsGrid = ({
  courseId,
  lessonsData,
}: {
  courseId: number
  lessonsData?: LessonShort[]
}) => {
  const navigate = useNavigate()

  const [lessons, setLessons] = useState(lessonsData || [])

  useEffect(() => {
    setLessons(lessonsData || [])
  }, [lessonsData])

  const [duplicateLessonId, setDuplicateLessonId] = useState<number>()

  const duplicateLessonMutation = useMutation(duplicateLesson)
  const handleDuplicateLesson = useCallback(
    async (courseId: number) => {
      duplicateLessonMutation.mutate(
        { id: duplicateLessonId!, courseId },
        {
          onSuccess: (response) => {
            setDuplicateLessonId(undefined)
            navigate(`/admin/lms/lesson-edit?id=${response.data.id}`, {
              state: {
                notification: 'Lesson has been successfully duplicated',
              },
            })
          },
        },
      )
    },
    [duplicateLessonId, duplicateLessonMutation, navigate],
  )

  const updateOrderOfLessonsMutation = useMutation(updateOrderOfCourseLessons)

  const handleRowDragEnd = useCallback(
    (e: RowDragEvent<LessonShort>) => {
      const orderedPointIds = e.api
        .getRenderedNodes()
        .filter((n) => !!n.data)
        .map((n) => n.data!.id)

      updateOrderOfLessonsMutation.mutate(
        { id: courseId, lessons: orderedPointIds },
        {
          onSuccess: () => {
            const orderedLessons = [...lessons].sort(
              (a, b) => orderedPointIds.indexOf(a.id) - orderedPointIds.indexOf(b.id),
            )
            setLessons(orderedLessons)
          },
          onError: () => {
            setLessons([...lessons])
          },
        },
      )
    },
    [courseId, lessons, updateOrderOfLessonsMutation],
  )

  const { idToRemove, openDeleteModal, closeDeleteModal, removeLoading, handleConfirmRemove } =
    useRemoveModalControl({
      deleteFunc: deleteLesson,
      successCallback: () => {
        if (idToRemove === null) return
        setLessons(lessons.filter((lesson) => lesson.id !== idToRemove))
      },
      warning: "Can't remove lesson from course",
    })

  const handleEditClick = useCallback(
    (id: number) => {
      navigate(withIdParam(routes.lessonEdit, id))
    },
    [navigate],
  )

  const handleDeleteClick = useCallback(
    (id: number) => {
      openDeleteModal(id)
    },
    [openDeleteModal],
  )

  const publishActionRender = useCallback(
    (props: ICellRendererParams<{ isPublished: boolean }>) => {
      const isPublished = props?.data?.isPublished
      return isPublished ? 'Unpublish' : 'Publish'
    },
    [],
  )

  const {
    action,
    idToPublish,
    openPublishModal,
    closePublishModal,
    publishLoading,
    handleConfirmPublish,
  } = usePublishModalControl({
    successCallback: (id: number, action: PublishAction) => {
      const isPublished = action == PublishAction.Publish
      const lesson = lessons.find((l) => l.id === id)
      if (!!lesson) {
        const updatedLesson: LessonShort = { ...lesson, isPublished }
        setLessons(lessons.map((l) => (l.id === id ? updatedLesson : l)))
      }
    },
    loadFunc: getLessonById,
    updateFunc: updateLesson,
  })

  const handlePublish = useCallback(
    async (id: number, props: ICellRendererParams<{ isPublished: boolean }>) => {
      try {
        const isPublished = props?.data?.isPublished
        const action = isPublished ? PublishAction.Unpublish : PublishAction.Publish
        if (!isPublished) {
          await checkLessonCanPublish({ id })
        }
        openPublishModal(id, action)
      } catch (e) {
        console.error(e)
      }
    },
    [openPublishModal],
  )

  const handleDuplicateClicked = useCallback((id: number) => {
    setDuplicateLessonId(id)
  }, [])

  const { moveAction, MoveModal } = useLessonMove(courseId)

  const actions = useMemo(() => {
    return [
      {
        name: 'Edit',
        onClick: handleEditClick,
      },
      {
        name: 'Delete',
        onClick: handleDeleteClick,
      },
      {
        renderName: publishActionRender,
        onClick: handlePublish,
      },
      {
        name: 'Duplicate',
        onClick: handleDuplicateClicked,
      },
      moveAction,
    ]
  }, [
    handleDeleteClick,
    handleDuplicateClicked,
    handleEditClick,
    handlePublish,
    publishActionRender,
    moveAction,
  ])

  const checkIsLessonPublished = useCallback(
    (lessonId: number) => {
      return !!lessons.find((lesson) => lesson.id === lessonId)?.isPublished
    },
    [lessons],
  )

  const { TagFilter, rowData, isDraggable } = useTagsFilterForDraggableTable<LessonShort>(
    lessons,
    'tags',
  )

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Lesson Name',
        flex: 1,
        rowDrag: isDraggable,
        field: 'name',
        sortable: false,
        cellRenderer: columnRenderers.createLink(
          {
            idSelector: (data) => data.id,
            nameSelector: (data) => data.name,
          },
          routes.lessonEdit,
        ),
      },
      {
        headerName: 'Link to Learn Library Article',
        field: 'link',
        flex: 1,
        sortable: false,
        cellRenderer: columnRenderers.createLink(
          {
            nameSelector: (data) => data?.learnArticle?.name,
            idSelector: (data) => data.learnArticle?.id,
          },
          routes.learnArticleEdit,
        ),
      },
      {
        headerName: 'Content',
        sortable: false,
        cellRenderer: (props: ICellRendererParams<LessonShort>) => {
          if (!props.data) return PLACEHOLDER

          return (
            <UIContentIcons
              isPublished={props.data.isPublished}
              hasImage={props.data.hasImage}
              hasVideo={props.data.hasVideo}
              hasAudio={props.data.hasAudio}
            />
          )
        },
      },
      {
        sortable: false,
        headerName: 'Quiz',
        field: 'quizId',
        flex: 1,
        cellRenderer: columnRenderers.checkbox(() => {}),
      },
      {
        sortable: false,
        headerName: 'Published',
        flex: 1,
        cellRenderer: columnRenderers.published,
      },
      createTagsColumn('tags'),
      {
        sortable: false,
        headerName: 'Creation Date',
        cellRenderer: columnRenderers.createdAt,
        colId: 'createdAt',
        flex: 1,
      },
      columnCreatedBy(),
      {
        sortable: false,
        headerName: 'Last Edited',
        cellRenderer: columnRenderers.updatedAt,
        minWidth: 220,
        colId: 'updatedAt',
        flex: 1,
      },
      columnUpdatedBy(),
      getActionColumn(actions),
    ] satisfies ColDef[]
  }, [actions, isDraggable])

  const csvFields = useLessonCSVField()

  const { onExportCSV } = useExportArrayCSV(
    rowData,
    csvFields,
    `Course View_${moment().format('YYYY-MM-DD')}.csv`,
  )

  return (
    <>
      {!!duplicateLessonId && (
        <LessonModalDuplicate
          onClose={() => setDuplicateLessonId(undefined)}
          onSubmit={handleDuplicateLesson}
          loading={duplicateLessonMutation.isLoading}
        />
      )}

      {MoveModal}

      <ConfirmRemoveModal
        entityToRemove="Lesson"
        loading={removeLoading}
        isOpen={idToRemove !== null}
        description={
          checkIsLessonPublished(idToRemove!)
            ? 'You are trying to delete the published Lesson. Please note that this Lesson will be deleted from end users Timeline.'
            : undefined
        }
        handleConfirm={handleConfirmRemove}
        handleClose={closeDeleteModal}
      />

      <ConfirmPublishModal
        loading={publishLoading}
        handleClose={closePublishModal}
        isOpen={idToPublish !== null}
        handleConfirm={handleConfirmPublish}
        action={action}
        entity="Lesson"
      />

      <TableToolbar
        left={<Box minWidth="200px">{TagFilter}</Box>}
        exportCSVEnable
        onExportCSV={onExportCSV}
      />

      <InfinityAgGrid<LessonShort>
        columnDefs={columnDefs}
        rowDragManaged={isDraggable}
        animateRows
        rowModelType="clientSide"
        rowData={rowData}
        rowDragMultiRow
        rowSelection="single"
        rowDragEntireRow
        floatingFiltersHeight={0}
        noRowsOverlayComponent={() => <UIMessageNoRows message="No lessons on course" />}
        onRowDragEnd={handleRowDragEnd}
      />
    </>
  )
}
