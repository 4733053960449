import React from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import ModalHeader from '../ModalHeader'
import styled from '@emotion/styled'

interface Props {
  handleClose: () => void
  retakeQuiz: () => void
  name: string
  learnMoreAvailable: boolean
  notificationText?: string | null
  onLearnMoreClicked: () => void
}

const Paragraph = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px !important;
`

const defaultResultText = `The Memory Care Partner App will provide more information about memory loss via short
  personalized articles in the Care Center as well as a reference articles in the Learn
  Library. Additionally, you should follow up with a medical care provider to evaluate your
  concerns and determine potential causes for any memory loss signs.`

const FinalScreen = (props: Props) => {
  const { handleClose, notificationText } = props
  const resultText = notificationText || defaultResultText

  return (
    <Stack spacing={2}>
      <ModalHeader handleClose={handleClose}>
        <Typography fontWeight={400} variant="h5">
          Results panel
        </Typography>
      </ModalHeader>

      <Typography>
        <Paragraph>{resultText}</Paragraph>
      </Typography>
      <Footer>
        {props.learnMoreAvailable && (
          <Button variant="contained" color="secondary" onClick={props.onLearnMoreClicked}>
            Learn More
          </Button>
        )}
      </Footer>
    </Stack>
  )
}

export default FinalScreen
