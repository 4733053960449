import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Stack, Typography } from '@mui/material'
import { updateQuery } from 'api/query'
import Breadcrumbs from 'components/Breadcumbs'
import FormInput from 'components/form-elements/FormInput'
import { Option } from 'components/form-elements/FormSelect'
import { AddQuestionModal } from 'components/modals/lms/AddQuestionToQuizModal'
import NotificationSys from 'components/NotificationSystem'
import PageTitle from 'components/page/PageTitle'
import { UIControlBanner, UIControlChangeName } from 'features/UI'
import { useCharactersLeft } from 'hooks/useCharactersLeft'
import { useShowControl } from 'hooks/useShowControl'
import React, { ChangeEvent, useCallback, useMemo } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { FormProvider, useForm } from 'react-hook-form'
import { routes } from 'routes/routes'
import { Query, QueryRange, QueryRequestUpdate } from 'types'
import { getDragDropListStyle, reorder } from 'utils'
import { TypeOf } from 'zod'
import { useQueryEditForm } from '../../hooks/useQueryEditForm'
import { QueryQuestion } from '../QueryQuestion/QueryQuestion'
import { QueryRanges } from '../QueryRanges/QueryRanges'

export function QueryPageEditForm({
  data,
  loading,
  setData,
  reloadData,
  setLoading,
}: {
  data: Query
  loading: boolean
  setData: (query: Query) => void
  reloadData: () => Promise<void>
  setLoading: (value: boolean) => void
}) {
  const [isOpenAddQuestion, openAddQuestion, closeAddQuestion] = useShowControl()

  const path = [
    {
      href: routes.manageQuery,
      text: 'Queries',
    },
    { text: `Query ${data.name}` },
  ]

  const optionsPrepared = useMemo((): QueryRequestUpdate => {
    const options: QueryRequestUpdate = {
      id: data.id,
      name: data.name,
      intro: data.intro || '',
      questions: data.questions.map((item) => item.id),
      learnArticleId: data.learnArticleId,
      bannerMediaFileId: data.bannerMediaFileId || null,
    }

    return options
  }, [data])

  const maxPoints = useMemo(() => {
    return data.questions.reduce((acc, question) => {
      const lastAnswer = question.answers[question.answers.length - 1]
      const points = lastAnswer ? lastAnswer.points || 0 : 0
      return acc + points
    }, 0)
  }, [data])

  const minPoints = useMemo(() => {
    return data.questions.reduce((acc, question) => {
      const lastAnswer = question.answers[0]
      const points = lastAnswer ? lastAnswer.points || 0 : 0
      return acc + points
    }, 0)
  }, [data])

  const { queryEditFormSchema } = useQueryEditForm()
  type QueryEditFormType = TypeOf<typeof queryEditFormSchema>

  const formSettings = {
    mode: 'onChange' as const,
    resolver: zodResolver(queryEditFormSchema),
    defaultValues: {
      introduction: optionsPrepared.intro,
      resultRanges: data.resultRanges,
    },
  }

  const methods = useForm<QueryEditFormType>(formSettings)

  const { handleSubmit } = methods

  const save = useCallback(
    async (options: Partial<QueryRequestUpdate>) => {
      try {
        setLoading(true)
        const newData = await updateQuery(options)
        setData(newData.data)
        NotificationSys.showSuccess('The Query was saved in the Learn Library Article')
      } catch (e) {
        throw new Error('An error occurred while saving the Query')
      } finally {
        setLoading(false)
      }
    },
    [setData, setLoading],
  )

  const handleSave = useCallback(() => {
    save(optionsPrepared)
  }, [optionsPrepared, save])

  const updateBannerData = useCallback(
    async (queryRequest: Query) => {
      const request = { ...optionsPrepared, name: queryRequest.name }
      if (queryRequest.bannerMediaFileId) {
        request.bannerMediaFileId = queryRequest.bannerMediaFileId
      }

      return updateQuery(request)
    },
    [optionsPrepared],
  )

  const handleAddQuestion = useCallback(
    (question: Option) => {
      if (optionsPrepared.questions.findIndex((it) => it === question.value) !== -1) {
        NotificationSys.showWarning(
          <>
            {"Can't add the question."}
            <br />
            Question is already in the list
          </>,
        )
        return
      }

      save({
        ...optionsPrepared,
        questions: optionsPrepared.questions.concat(question.value as number),
      })
    },
    [optionsPrepared, save],
  )

  const handleRemoveQuestion = useCallback(
    (idToDelete: number) => {
      const questions = optionsPrepared.questions.filter((item) => item !== idToDelete)

      if (data.isPublished && questions.length === 0) {
        NotificationSys.showWarning(
          "Can't delete the question. The query should contain at last 1 question.",
        )
        return
      }

      save({
        ...optionsPrepared,
        questions,
      })
    },
    [data, optionsPrepared, save],
  )

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return

      const reorderedQuestions = reorder(
        optionsPrepared.questions,
        result.source.index,
        result.destination.index,
      )

      save({ ...optionsPrepared, questions: reorderedQuestions })
    },
    [optionsPrepared, save],
  )

  const handleChangeIntro = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setData({ ...data, intro: event.target.value })
    },
    [data, setData],
  )

  const handleDragEndRanges = useCallback(
    (reorderedRanges: QueryRange[]) => {
      setData({ ...data, resultRanges: reorderedRanges })
    },
    [data, setData],
  )

  const charactersLeft = useCharactersLeft(500, optionsPrepared.intro.length)

  return (
    <>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(handleSave)} width="100%" mb={4} noValidate>
          <PageTitle mb={2}>Query View</PageTitle>

          <Stack spacing={2}>
            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={2} alignItems="center">
                <Breadcrumbs path={path} />

                <UIControlChangeName<Query>
                  entityName="Query"
                  data={data}
                  setData={setData}
                  loading={loading}
                  setLoading={setLoading}
                  updateRequest={updateBannerData}
                />
              </Stack>
              <Button variant="contained" type="submit">
                Save
              </Button>
            </Stack>

            <UIControlBanner<Query, 'name'>
              entityName="Query"
              nameField="name"
              data={data}
              setData={setData}
              setLoading={setLoading}
              updateRequest={updateBannerData}
            />

            <Stack spacing={0.5}>
              <Typography>Query Introduction</Typography>
              <FormInput
                name="introduction"
                label="Introduction"
                placeholder="Start typing"
                fullWidth
                minRows={3}
                maxRows={6}
                multiline
                onChange={handleChangeIntro}
                helperText={charactersLeft}
                checkTouchField={false}
              />
            </Stack>

            {/* --- Questions --- */}
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
              <Typography variant="h6" fontWeight={400}>
                Questions
              </Typography>
              <Button variant="contained" onClick={openAddQuestion}>
                Add Question
              </Button>
            </Stack>

            {data.questions.length === 0 && (
              <Typography color="textSecondary">
                No questions were added yet. Please add questions.
              </Typography>
            )}

            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getDragDropListStyle(snapshot.isDraggingOver)}
                  >
                    {data.questions.map((item, index) => (
                      <QueryQuestion
                        key={item.id}
                        question={item}
                        index={index}
                        onDelete={handleRemoveQuestion}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <Stack>
              <Typography>Total maximum points: {maxPoints}</Typography>
              <Typography>Total minimum points: {minPoints}</Typography>
            </Stack>
          </Stack>
        </Box>
      </FormProvider>

      <Stack pb={5}>
        <QueryRanges
          queryId={data.id}
          ranges={data.resultRanges}
          minPoints={minPoints}
          maxPoints={maxPoints}
          onReloadQuery={reloadData}
          onDragEnd={handleDragEndRanges}
        />
      </Stack>

      <AddQuestionModal
        entityId={String(data.id)}
        type="query"
        isOpen={isOpenAddQuestion}
        addedIds={optionsPrepared.questions}
        handleClose={closeAddQuestion}
        onAdd={handleAddQuestion}
      />
    </>
  )
}
