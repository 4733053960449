import IconZoomIn from '@mui/icons-material/ZoomIn'
import IconZoomOut from '@mui/icons-material/ZoomOut'
import { Box, Button, IconButton, Slider, Stack } from '@mui/material'
import * as fabric from 'fabric'
import type { TDataUrlOptions } from 'fabric/src/typedefs'
import { useEffectOnce } from 'hooks/useEffectOnce'
import { useCallback, useRef, useState } from 'react'
import { ZOOM_MAX, ZOOM_MIN, ZOOM_STEP } from '../../models/ImageEditor.model'

export function ImageEditor({
  imgData,
  hasRatioRestrictions,
  onSave,
}: {
  imgData: string
  hasRatioRestrictions: boolean
  onSave: (base64: string) => void
}) {
  const containerRef = useRef(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null)
  const [scale, setScale] = useState<number>(1)
  const [isCropping, setIsCropping] = useState(false)
  const [zoom, setZoom] = useState<number>(100)

  const getElementById = (id: string) => {
    if (!canvas) return

    return canvas.getObjects().find((obj) => obj.get('id') === id)
  }

  const handleExport = () => {
    if (!canvas) return

    const image = getElementById('image') as fabric.Image
    if (!image) return

    const exportOptions: TDataUrlOptions = {
      format: 'png',
      quality: 1,
      multiplier: 1 / scale,
    }

    if (image.clipPath) {
      handleZoom(100)
      exportOptions.left = image.clipPath.left
      exportOptions.top = image.clipPath.top
      exportOptions.width = image.clipPath.width * image.clipPath.scaleX
      exportOptions.height = image.clipPath.height * image.clipPath.scaleY
    }

    const dataURL = canvas.toDataURL(exportOptions)
    onSave(dataURL)
  }

  const handleZoom = (zoomValue: number) => {
    if (!canvas) return

    setZoom(zoomValue)
    canvas.zoomToPoint(new fabric.Point(canvas.width / 2, canvas.height / 2), zoomValue / 100)
    canvas.renderAll()
  }

  const handleZoomChange = (_: Event, newValue: number | number[]) => {
    if (!canvas) return

    const zoomLevel = Array.isArray(newValue) ? newValue[0] : newValue
    handleZoom(zoomLevel)
  }

  const handleZoomOut = () => {
    if (!canvas) return

    const zoomValue = zoom - ZOOM_STEP
    if (zoomValue < ZOOM_MIN) return

    handleZoom(zoomValue)
  }

  const handleZoomIn = () => {
    if (!canvas) return
    if (zoom >= ZOOM_MAX) return

    const zoomValue = zoom + ZOOM_STEP
    if (zoomValue > ZOOM_MAX) return

    handleZoom(zoomValue)
  }

  const handleCropSelectionReset = () => {
    if (!canvas) return

    const cropRect = getElementById('cropRect')
    if (!cropRect) return

    canvas.setActiveObject(cropRect)
    canvas.renderAll()
  }

  const restrictCropMoving = (e: fabric.BasicTransformEvent) => {
    if (!canvas) return

    const obj = e.transform.target
    if (!obj) return

    // Restrict movement within canvas boundaries
    if (obj.left < 0) {
      obj.left = 0
    }
    if (obj.top < 0) {
      obj.top = 0
    }
    if (obj.left + obj.width > canvas.width) {
      obj.left = canvas.width - obj.width
    }
    if (obj.top + obj.height > canvas.height) {
      obj.top = canvas.height - obj.height
    }
  }

  const handleCropStart = () => {
    if (!canvas) return
    const image = getElementById('image') as fabric.Image
    if (!image) return

    const cropWidth = Math.min(canvas.width / 2, image.width * image.scaleX)
    const cropHeight = Math.min(canvas.height / 2, (cropWidth * 9) / 16)

    const cropRect = new fabric.Rect({
      left: (canvas.width - cropWidth) / 2,
      top: (canvas.height - cropHeight) / 2,
      width: cropWidth,
      height: cropHeight,
      absolutePositioned: true,
      opacity: 0.2,
      lockRotation: true,
    })
    cropRect.set('id', 'cropRect')
    if (hasRatioRestrictions) {
      cropRect.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
      })
    }

    canvas.add(cropRect)
    canvas.setActiveObject(cropRect)

    cropRect.on('deselected', handleCropSelectionReset)
    cropRect.on('moving', restrictCropMoving)

    canvas.renderAll()

    setIsCropping(true)
  }

  const handleCropDone = () => {
    if (canvas) {
      setIsCropping(false)
      const image = getElementById('image') as fabric.Image
      if (!image) return

      const cropRect = getElementById('cropRect') as fabric.Image
      if (!cropRect) return

      image.clipPath = cropRect
      cropRect.off('deselected', handleCropSelectionReset)
      cropRect.off('moving', restrictCropMoving)
      canvas.remove(cropRect)
      canvas.renderAll()
      handleExport()
    }
  }

  const handleCropCancel = () => {
    if (!canvas) return
    const cropRect = getElementById('cropRect') as fabric.Image
    if (!cropRect) return

    cropRect.off('deselected', handleCropSelectionReset)
    cropRect.off('moving', restrictCropMoving)

    canvas.remove(cropRect)
    canvas.renderAll()
    setIsCropping(false)
  }

  const initCanvas = useCallback(() => {
    if (!containerRef.current || !canvasRef.current) return

    const { offsetWidth } = containerRef.current
    const width = offsetWidth - 2 // border width

    const fabricCanvas = new fabric.Canvas(canvasRef.current, {
      backgroundColor: '#ffffff',
      width,
      height: (width * 9) / 16,
      preserveObjectStacking: true,
    })
    return fabricCanvas
  }, [])

  const addImage = useCallback(
    (fabricCanvas: fabric.Canvas) => {
      const image = new Image()
      image.crossOrigin = 'anonymous'
      image.src = imgData
      image.onload = () => {
        const img = new fabric.Image(image)
        img.set('id', 'image')
        img.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
        })
        if (img.width > img.height) {
          img.scaleToWidth(fabricCanvas.get('width'))
        } else {
          img.scaleToHeight(fabricCanvas.get('height'))
        }
        setScale(img.scaleX)
        fabricCanvas.add(img)
        fabricCanvas.setActiveObject(img)
        fabricCanvas.centerObject(img)
        fabricCanvas.renderAll()
      }
    },
    [imgData],
  )

  useEffectOnce(() => {
    const fabricCanvas = initCanvas()
    if (!fabricCanvas) return

    addImage(fabricCanvas)
    setCanvas(fabricCanvas)
  })

  return (
    <Stack className="gap-16">
      <Box sx={{ border: '1px solid #ccc' }} ref={containerRef}>
        <canvas ref={canvasRef} />
      </Box>

      <Stack direction="row" justifyContent="flex-end" alignItems="center" className="gap-16">
        <Stack direction="row" alignItems="center" className="gap-8" width={300}>
          <IconButton onClick={handleZoomOut}>
            <IconZoomOut />
          </IconButton>

          <Slider
            value={zoom}
            min={ZOOM_MIN}
            max={ZOOM_MAX}
            step={ZOOM_STEP}
            onChange={handleZoomChange}
            valueLabelDisplay="auto"
          />

          <IconButton onClick={handleZoomIn}>
            <IconZoomIn />
          </IconButton>
        </Stack>

        {!isCropping ? (
          <>
            <Button variant="outlined" onClick={handleCropStart}>
              Crop
            </Button>
            <Button variant="outlined" onClick={handleExport}>
              Save
            </Button>
          </>
        ) : (
          <>
            <Button variant="outlined" onClick={handleCropCancel}>
              Cancel crop
            </Button>
            <Button variant="outlined" onClick={handleCropDone}>
              Save
            </Button>
          </>
        )}
      </Stack>
    </Stack>
  )
}
