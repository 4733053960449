import { ICellRendererParams } from 'ag-grid-community'
import { PLACEHOLDER } from 'components/column-renderers'
import { QuestionLinkedObjectsCell } from '../components/QuestionLinkedObjectsCell/QuestionLinkedObjectsCell'
import { EQuestionType, Lesson, Query } from 'types'

export function linkedObjectsCellRenderer(
  props: ICellRendererParams<{ queries: Query[]; quizLessons: Lesson[]; type: EQuestionType }>,
) {
  const type = props.data?.type
  const objects = type === EQuestionType.question ? props.data?.quizLessons : props.data?.queries
  const title = type === EQuestionType.question ? 'Lessons' : 'Queries'
  if (objects?.length) {
    return <QuestionLinkedObjectsCell title={title} objects={objects} />
  }
  return PLACEHOLDER
}
