import { getCourseById } from 'api/courses'
import { Option } from 'components/form-elements/FormSelect'
import ModalWithTextAndIcon, {
  NameAndIconRequest,
} from 'components/modals/simple-modals/ModalWithTextAndIcon'
import ModalWithTextInput from 'components/modals/simple-modals/ModalWithTextInput'
import NotificationSys from 'components/NotificationSystem'
import FailPlaceholder from 'components/placeholders/FailPlaceholder'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import { useEntityDataControl } from 'hooks/useEntityDataControl'
import { useShowControl } from 'hooks/useShowControl'
import { ExamTitleMap } from 'models/exam.model'
import React, { ChangeEvent, useCallback, useMemo } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { useQuery } from 'react-query'
import {
  Course,
  Questionnaire,
  QuestionnaireRequestUpdate,
  Quiz,
  QuizRequestUpdate,
  Response,
} from 'types'
import { reorder, useSearchParamsObject } from 'utils'
import { ExamParamsByType } from '../../models/Exam.model'
import { getPath } from '../../models/getPath'
import { ExamPageEditForm } from '../ExamPageEditForm/ExamPageEditForm'

export function ExamPageEdit() {
  const searchParams = useSearchParamsObject()
  const id = searchParams.id as string
  const type = searchParams.type as 'quiz' | 'questionnaire'

  const [isNameModalOpen, openNameModal, closeNameModal] = useShowControl()
  const [isOpenAddQuestion, openAddQuestion, closeAddQuestion] = useShowControl()

  const { data, loading, setLoading, setData } = useEntityDataControl({
    id,
    loadFunc: ExamParamsByType[type].apiMethodLoad,
    warning: "Can't load data",
  })

  const { data: course, isLoading: isCourseLoading } = useQuery<
    Response<Course> | null,
    unknown,
    Course | undefined
  >(
    ['examCourse'],
    () => {
      const courseId = (data as Quiz)?.lesson?.courseId
      if (!courseId) return null

      return getCourseById({ id: courseId })
    },
    {
      enabled: !!data && type === 'quiz',
      select: (courseData) => courseData?.data,
    },
  )

  const isLoading = useMemo(() => {
    if (type === 'quiz') {
      return loading || isCourseLoading
    }

    return loading
  }, [isCourseLoading, loading, type])

  const optionsPrepared = useMemo((): QuizRequestUpdate | QuestionnaireRequestUpdate | null => {
    if (!data) return null

    if (type === 'quiz') {
      return {
        id: data.id,
        name: data.name,
        intro: data.intro,
        questions: data.questions.map((item) => item.id),
        lessonId: (data as Quiz).lessonId,
      }
    }

    return {
      id: data.id,
      name: data.name,
      intro: data.intro,
      questions: data.questions.map((item) => item.id),
      courseId: (data as Questionnaire).courseId,
      bannerMediaFileId: (data as Questionnaire).bannerMediaFileId,
    }
  }, [data, type])

  const save = useCallback(
    async (options: QuizRequestUpdate | QuestionnaireRequestUpdate) => {
      const { apiMethodSave, messageSuccess } = ExamParamsByType[type]
      try {
        setLoading(true)
        const newData = await apiMethodSave(options)
        setData(newData.data)
        NotificationSys.showSuccess(messageSuccess)
      } finally {
        setLoading(false)
      }
    },
    [setData, setLoading, type],
  )

  const handleSaveClick = useCallback(() => {
    if (!optionsPrepared) return

    save(optionsPrepared)
  }, [optionsPrepared, save])

  const handleAddQuestion = useCallback(
    (question: Option) => {
      if (!optionsPrepared) return

      if (optionsPrepared.questions.findIndex((it) => it === question.value) !== -1) {
        NotificationSys.showWarning(
          <>
            {"Can't add the question."}
            <br />
            Question is already in the list
          </>,
        )
        return
      }

      save({
        ...optionsPrepared,
        questions: optionsPrepared.questions.concat(question.value as number),
      })
    },
    [optionsPrepared, save],
  )

  const handleRemoveQuestion = useCallback(
    (idToDelete: number) => {
      if (!optionsPrepared) return

      const questions = optionsPrepared.questions.filter((item) => item !== idToDelete)

      if (type === 'quiz') {
        if (questions.length === 0) {
          NotificationSys.showWarning('The Quiz should contain at least 1 question')
          return
        }

        if (course?.questionnaire) {
          const isQuestionInExam =
            course.questionnaire.questions.filter((item) => item.id === idToDelete).length > 0
          if (isQuestionInExam) {
            NotificationSys.showWarning(
              'Can not delete the question from Quiz. Please delete the question from the exam first',
            )
            return
          }
        }
      }

      if (type === 'questionnaire') {
        if (questions.length < 3) {
          NotificationSys.showWarning('The Exam should contain at least 3 questions')
          return
        }
      }

      save({
        ...optionsPrepared,
        questions,
      })
    },
    [course, optionsPrepared, save, type],
  )

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!optionsPrepared || !result.destination) return

      const reorderedQuestions = reorder(
        optionsPrepared.questions,
        result.source.index,
        result.destination.index,
      )

      save({ ...optionsPrepared, questions: reorderedQuestions })
    },
    [optionsPrepared, save],
  )

  const handleChangeName = useCallback(
    async (name: string) => {
      if (!optionsPrepared) return

      await save({ ...optionsPrepared, name })
      closeNameModal()
    },
    [closeNameModal, optionsPrepared, save],
  )

  const handleChangeNameAndIcon = useCallback(
    async (values: NameAndIconRequest) => {
      if (!optionsPrepared || type === 'quiz') return

      await save({
        ...optionsPrepared,
        name: values.name,
        bannerMediaFileId:
          values.icon || (optionsPrepared as QuestionnaireRequestUpdate).bannerMediaFileId,
      })
      closeNameModal()
    },
    [closeNameModal, optionsPrepared, save, type],
  )

  const handleChangeIntro = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!data) return

      setData({ ...data, intro: event.target.value })
    },
    [data, setData],
  )

  if (isLoading) {
    return <LoadingPlaceholder />
  }

  if (!data) {
    return <FailPlaceholder error={ExamParamsByType[type].messageFail} />
  }

  return (
    <>
      <ExamPageEditForm
        id={id}
        type={type}
        path={getPath(type, data)}
        intro={data.intro ?? ''}
        questions={data.questions}
        isOpenAddQuestion={isOpenAddQuestion}
        onClickSave={handleSaveClick}
        onChangeIntro={handleChangeIntro}
        openAddQuestion={openAddQuestion}
        onDragEnd={handleDragEnd}
        removeQuestion={handleRemoveQuestion}
        closeAddQuestion={closeAddQuestion}
        onAddQuestion={handleAddQuestion}
        onEditName={openNameModal}
      />

      {isNameModalOpen && type !== 'questionnaire' && (
        <ModalWithTextInput
          value={data?.name}
          loading={loading}
          title={`Change ${ExamTitleMap[type]} name`}
          label={`${ExamTitleMap[type]} name`}
          buttonText="Save"
          isOpen
          handleClose={closeNameModal}
          onSubmit={handleChangeName}
        />
      )}

      {isNameModalOpen && type === 'questionnaire' && (
        <ModalWithTextAndIcon
          value={{
            name: data.name,
            icon: (data as Questionnaire).bannerUrl,
          }}
          title={`Change ${ExamTitleMap[type]} name`}
          label={`${ExamTitleMap[type]} name`}
          buttonText="Save"
          hasRatioRestrictions
          isOpen
          handleClose={closeNameModal}
          loading={loading}
          setLoading={setLoading}
          onSubmit={handleChangeNameAndIcon}
        />
      )}
    </>
  )
}
