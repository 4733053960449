import { moveLearnArticle } from 'api/learnArticles'
import { getLibraryTopics } from 'api/learnTopics'
import { UIModalSelectEntity } from 'features/UI'
import { useCallback, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router'
import { routes } from 'routes/routes'
import { withIdParam } from 'utils'

export function useLearnLibraryArticleMove(parentId: number) {
  const navigate = useNavigate()
  const [articleId, setArticleId] = useState<number>()

  const clearId = useCallback(() => {
    setArticleId(undefined)
  }, [])

  const moveMutation = useMutation(moveLearnArticle)

  const handleDuplicate = useCallback(
    async (topicId: number) => {
      if (articleId === undefined) return

      moveMutation.mutate(
        { id: articleId, topicId },
        {
          onSuccess: () => {
            navigate(withIdParam(routes.learnArticleEdit, articleId), {
              state: {
                notification: 'Learn Library Article has been successfully moved',
              },
            })
            setArticleId(undefined)
          },
        },
      )
    },
    [articleId, moveMutation, navigate],
  )

  const handleMoveClicked = useCallback((id: number) => {
    setArticleId(id)
  }, [])

  const action = useMemo(
    () => ({
      name: 'Move',
      onClick: handleMoveClicked,
    }),
    [handleMoveClicked],
  )

  const MoveModal = useMemo(() => {
    if (!articleId) return null

    return (
      <UIModalSelectEntity
        queryId="getLibraryTopics"
        parentEntityId={parentId}
        apiRequest={getLibraryTopics}
        title="Move Learn Library Article"
        label="Pick a topic"
        onClose={clearId}
        onSubmit={handleDuplicate}
        loading={moveMutation.isLoading}
      />
    )
  }, [articleId, parentId, clearId, handleDuplicate, moveMutation.isLoading])

  return {
    moveAction: action,
    MoveModal,
  }
}
