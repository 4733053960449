import {
  getLibraryArticleById,
  getLibraryArticles,
  getLibraryArticlesByName,
} from 'api/learnArticles'
import { pathwayCacheTime } from 'models/pathways.model'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { BaseTableRequest } from 'types'
import { selectOptionsFromTableResponse } from 'utils'
import { Option } from '../../form-elements/FormSelect'
import ModalWithSelect from '../simple-modals/ModalWithSelect'

interface Props {
  isOpen: boolean
  handleClose: () => void
  value?: number | null
  customTableRequest?: BaseTableRequest
  onSubmit: (learnArticleId: number) => void
}

const LinkToLearnLibraryModal = (props: Props) => {
  const { isOpen, handleClose, customTableRequest, value, onSubmit } = props

  const [search, setSearch] = useState('')

  const selectedElementQuery = useQuery(
    ['selectedElementQuery', { value }],
    () => getLibraryArticleById({ id: value || 0 }),
    {
      enabled: isOpen && value !== null,
      cacheTime: pathwayCacheTime,
    },
  )

  const learnLibraryQuery = useQuery(
    ['getLibraryArticles', { search }],
    () => {
      if (customTableRequest) {
        const filter = { ...customTableRequest.filter }
        if (filter && search !== '') {
          filter.name = {
            type: 'text',
            op: 'contains',
            value: search,
          }
        }

        const request: BaseTableRequest = {
          ...customTableRequest,
          filter,
        }

        return getLibraryArticles(request)
      }

      return getLibraryArticlesByName(search)
    },
    {
      enabled: isOpen && !selectedElementQuery.isLoading,
      select: selectOptionsFromTableResponse,
      cacheTime: pathwayCacheTime,
    },
  )

  const options = useMemo(() => {
    const base = (learnLibraryQuery.data as unknown as Option[]) ?? []
    const selected = selectedElementQuery.data?.data
    if (selected) {
      const el: Option = {
        value: selected.id,
        label: selected.name,
      }
      if (base.some((it) => it.value === selected.id)) {
        return base
      }
      return base.concat(el)
    }
    return base
  }, [learnLibraryQuery.data, selectedElementQuery.data?.data])

  return (
    <ModalWithSelect
      value={value}
      title="Change Link to Learn Library"
      label="Link to Learn Library"
      buttonText="Save"
      isOpen={isOpen}
      handleClose={handleClose}
      onSubmit={onSubmit}
      options={options}
      loading={false}
      loadingOptions={learnLibraryQuery.isLoading || selectedElementQuery.isLoading}
      onChangeInput={setSearch}
    />
  )
}

export default React.memo(LinkToLearnLibraryModal)
