import { Box, Button, Stack } from '@mui/material'
import {
  addCareArticleEngagementMetric,
  addLearnArticleEngagementMetric,
  addLessonEngagementMetric,
  addLessonViewMetric,
} from 'api/metrics'
import { useAuthContext } from 'components/context/AuthProvider'
import { useDrawersContext } from 'components/context/ClientsDrawersProvider'
import { ArticleAudioUserView } from 'features/Article/components/ArticleAudioUserView/ArticleAudioUserView'
import { ArticlePreview } from 'features/Article/components/ArticlePreview/ArticlePreview'
import React, { useCallback, useEffect, useMemo } from 'react'
import { routes } from 'routes/routes'
import { useMetric } from 'services/Metric'
import { useActions } from 'store/hooks'
import {
  ArticleType,
  CareArticle,
  ETimelinePostStatus,
  IMetricScope,
  IMetricType,
  LearnArticle,
  Lesson,
} from 'types'
import { formatMetricDate, getRoute, useSearchParamsObject } from 'utils'
import { ArticleHeader } from '../../ArticleHeader'

const isLesson = (obj: Lesson | LearnArticle | CareArticle): obj is Lesson => {
  return !!(obj as Lesson).courseId
}

const isLearnArticle = (obj: Lesson | LearnArticle | CareArticle): obj is LearnArticle => {
  return !!(obj as LearnArticle).topicId
}

const isCareArticle = (obj: Lesson | LearnArticle | CareArticle): obj is CareArticle => {
  return !isLesson(obj) && !isLearnArticle(obj)
}

export function ViewArticleDrawerContent({
  data,
  type,
  status,
  quizStatus,
  handleClose,
  onUpdateStatus,
  onChangeRoute,
}: {
  data: Lesson | LearnArticle | CareArticle
  type: ArticleType
  status?: ETimelinePostStatus
  quizStatus: string | null
  handleClose: (shouldClearUuid: boolean) => void
  onUpdateStatus: (status: ETimelinePostStatus, isSilent: boolean) => void
  onChangeRoute: (route: string) => void
}) {
  const { fromId, fromType } = useSearchParamsObject()

  const onReviewLater = () => {
    onUpdateStatus(ETimelinePostStatus.review, false)
  }

  const onDone = () => {
    onUpdateStatus(ETimelinePostStatus.done, false)
  }

  const { user } = useAuthContext()
  const { getTimelineSilent } = useActions()

  const lesson = useMemo(() => {
    if (isLesson(data)) {
      return data
    }
  }, [data])

  const learnArticle = useMemo(() => {
    if (isLearnArticle(data)) {
      return data
    }
  }, [data])

  const careArticle = useMemo(() => {
    if (isCareArticle(data)) {
      return data
    }
  }, [data])

  const careArticleType = useMemo(() => {
    if (isCareArticle(data)) {
      return data.type
    }

    return null
  }, [data])

  const metricOptions = useMemo(() => {
    if (!!lesson) {
      return { type: IMetricType.lesson, scope: IMetricScope.lesson }
    }

    if (!!learnArticle) {
      return { type: IMetricType.learnArticle, scope: IMetricScope.learnArticle }
    }

    if (!!careArticle) {
      return { type: IMetricType.careArticle, scope: IMetricScope.careArticle }
    }

    return { type: undefined, scope: undefined }
  }, [careArticle, learnArticle, lesson])

  const handleSaveMetric = useCallback(
    async (value: number, uuid: string, scope: IMetricScope) => {
      if (!user) {
        return
      }

      if (type === ArticleType.Lesson) {
        await addLessonEngagementMetric({
          lessonUuid: uuid,
          lessonId: data.id,
          courseId: (data as Lesson).courseId,
          userId: user.id,
          engagedFrom: formatMetricDate(value),
          engagedTo: formatMetricDate(),
          scope,
        })
      } else if (type === ArticleType.LearnLibrary) {
        await addLearnArticleEngagementMetric({
          userId: user.id,
          learnArticleId: data.id,
          learnTopicId: (data as LearnArticle).topicId,
          scope,
          engagedFrom: formatMetricDate(value),
          engagedTo: formatMetricDate(),
        })
      } else if (type === ArticleType.CareArticle) {
        await addCareArticleEngagementMetric({
          userId: user.id,
          careArticleId: data.id,
          scope,
          engagedFrom: formatMetricDate(value),
          engagedTo: formatMetricDate(),
        })
      }
    },
    [data, type, user],
  )

  useMetric({
    type: metricOptions.type,
    scope: metricOptions.scope,
    entityId: data.id,
    hasIdleTimer: true,
    idleLimitMin: 2,
    onSave: handleSaveMetric,
  })

  useEffect(() => {
    if (!!lesson) {
      addLessonViewMetric({
        lessonId: lesson.id,
        courseId: (data as Lesson).courseId,
        userId: user?.id ?? 0,
        viewedAt: formatMetricDate(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { handleOpenQuestioning: handleOpenQuestionnaire } = useDrawersContext()

  const onQuizTaken = useCallback(() => {
    getTimelineSilent()
  }, [getTimelineSilent])

  const onClickTakeQuiz = useCallback(() => {
    if (lesson?.quizId) {
      handleOpenQuestionnaire({
        id: lesson?.quizId,
        mode: 'take',
        type: 'quiz',
        onQuestioningTaken: onQuizTaken,
      })
      handleClose(false)
    }
  }, [handleClose, handleOpenQuestionnaire, lesson, onQuizTaken])

  const onClose = useCallback(() => {
    handleClose(true)
  }, [handleClose])

  const onClickReturnBack = useCallback(() => {
    if (fromId && fromType) {
      const route = getRoute(routes.journeyItem, {
        journeyItemType: fromType,
        journeyItemId: fromId,
      })
      onChangeRoute(route)
    }
  }, [fromId, fromType, onChangeRoute])

  const onClickLearnMore = useCallback(() => {
    let route = null
    if (!!learnArticle?.careArticleId) {
      route = getRoute(routes.learnLibraryItem, {
        learnLibraryItemType: ArticleType.CareArticle,
        learnLibraryItemId: learnArticle.careArticleId,
      })
    } else if (!!careArticle?.learnArticleId) {
      route = getRoute(
        routes.journeyItem,
        {
          journeyItemType: ArticleType.LearnLibrary,
          journeyItemId: careArticle.learnArticleId,
        },
        {
          fromId: careArticle.id.toString(),
          fromType: type,
        },
      )
    } else if (!!lesson?.learnArticleId) {
      route = getRoute(
        routes.journeyItem,
        {
          journeyItemType: ArticleType.LearnLibrary,
          journeyItemId: lesson.learnArticleId,
        },
        {
          fromId: lesson.id.toString(),
          fromType: ArticleType.Lesson,
        },
      )
    }

    if (route) {
      onChangeRoute(route)
    }
  }, [learnArticle, careArticle, lesson, type, onChangeRoute])

  return (
    <>
      <ArticleHeader
        type={type}
        careArticleType={careArticleType}
        date={data.createdAt}
        articleTitle={data.post || data.name}
        handleClose={onClose}
      />
      <ArticleAudioUserView
        enMediaUrl={data.enMediaUrl}
        enMediaFileMimeType={data.enMediaFile?.mimeType}
        esMediaUrl={data.esMediaUrl}
        esMediaFileMimeType={data.esMediaFile?.mimeType}
        metricType={metricOptions.type}
        metricEntityId={data.id}
        onSaveMetric={handleSaveMetric}
      />
      <Box mt={2} mb={2} fontSize={20} minHeight="128px">
        <ArticlePreview
          data={data.article}
          metricType={metricOptions.type}
          metricEntityId={data.id}
          onSaveVideoMetric={handleSaveMetric}
        />
      </Box>
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          {isLesson(data) && !!data.quizId && (
            <Button variant="contained" color="secondary" onClick={onClickTakeQuiz}>
              Take quiz
            </Button>
          )}
          {!!learnArticle && fromId && (
            <Button variant="contained" color="secondary" onClick={onClickReturnBack}>
              {fromType === ArticleType.Lesson && 'Return to Lesson'}
              {fromType === ArticleType.CareArticle && 'Return to Post'}
            </Button>
          )}
          {!!careArticle && !!careArticle.learnArticleId && (
            <Button variant="contained" color="secondary" onClick={onClickLearnMore}>
              Learn More
            </Button>
          )}
          {!!lesson && !!lesson.learnArticleId && (
            <Button variant="contained" color="secondary" onClick={onClickLearnMore}>
              Learn More
            </Button>
          )}
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          {isLesson(data) &&
            status &&
            status !== ETimelinePostStatus.done &&
            quizStatus !== 'passed' && (
              <Button variant="contained" onClick={onClose}>
                Continue Later
              </Button>
            )}

          {isLesson(data) && quizStatus === 'passed' && (
            <Button variant="contained" onClick={onDone}>
              Done
            </Button>
          )}

          {isLearnArticle(data) && !fromId && (
            <>
              <Button variant="contained" onClick={onReviewLater}>
                Continue Later
              </Button>
              <Button variant="contained" onClick={onDone}>
                Done
              </Button>
            </>
          )}

          {isCareArticle(data) && (
            <>
              <Button variant="contained" onClick={onReviewLater}>
                Continue Later
              </Button>
              <Button variant="contained" onClick={onDone}>
                Done
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </>
  )
}
