import styled from '@emotion/styled'
import { Button, Stack, Typography } from '@mui/material'
import green from '@mui/material/colors/green'
import { useDrawersContext } from 'components/context/ClientsDrawersProvider'
import iconSmQuestion from 'images/icons/icon_sm_question.png'
import moment from 'moment'
import React from 'react'
import { QueryAnsweredQuestion } from 'types'

interface Props {
  title: string
  passedOn: string
  passedBy: string
  id: number
  answers: QueryAnsweredQuestion[]
  bannerUrl: string | null
  learnArticleId: number | null
  notificationText: string | null
}

const sx = {
  background: 'white',
  paddingY: 2.5,
  paddingX: 2,
  borderRadius: 2,
  width: '100%',
  cursor: 'pointer',
}

const IconContainer = styled('div')`
  width: 57px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
`

const QueryItem = (props: Props) => {
  const { title, passedOn, id, bannerUrl, answers, learnArticleId, notificationText } = props

  const { handleOpenQuestioning: handleOpenQuestionnaire } = useDrawersContext()

  const onClick = () => {
    handleOpenQuestionnaire({
      id: id,
      mode: 'view_results',
      type: 'query',
      extraParameters: {
        answers,
        learnArticleId,
        notificationText,
      },
    })
  }

  const iconSrc = bannerUrl || iconSmQuestion

  return (
    <Stack sx={sx} onClick={onClick} display="flex">
      <Stack direction="row" spacing={2} alignItems="center">
        <IconContainer>
          <img src={iconSrc} />
        </IconContainer>
        <Stack sx={{ flex: 1 }}>
          <Typography fontWeight={400} variant="h6" fontSize="1rem">
            <span style={{ fontSize: '1.12rem' }}>{title}</span>
          </Typography>
          <Typography variant="subtitle2" fontWeight={200}>
            Taken on {moment(passedOn).format('MM/DD/yy')}
          </Typography>
        </Stack>
        <Button variant="text">View Results</Button>
      </Stack>
    </Stack>
  )
}

export default QueryItem
