import { getLibraryArticles } from 'api/learnArticles'
import LinkToLearnLibraryModal from 'components/modals/lms/LinkToLearnLibraryModal'
import { UIEntityItemConnection } from 'features/UI'
import { useShowControl } from 'hooks/useShowControl'
import { useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'
import { BaseTableRequest } from 'types'
import { isDefined } from 'utils'

export function QueryRangeArticle({
  value,
  onChange,
  disabled = false,
}: {
  value?: number | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...event: any[]) => void
  disabled?: boolean
}) {
  const learnLibraryByIdQuery = useQuery(
    ['getLibraryArticleById', value],
    () => {
      let filter = {}
      if (isDefined(value)) {
        filter = {
          id: {
            type: 'number',
            op: 'equals',
            value,
          },
        }
      }

      return getLibraryArticles({
        filter,
      })
    },
    {},
  )

  const learnLibraryRequest: BaseTableRequest = useMemo(() => {
    return {
      filter: {
        isPublished: { type: 'boolean', op: 'equals', value: true },
      },
      pagination: {
        page: 1,
        pageSize: 500,
      },
      sort: null,
    }
  }, [])

  const getArticleNameById = useCallback(
    (id: number | null | undefined) => {
      if (!id) return ''

      const articles = learnLibraryByIdQuery?.data?.data?.rows || []
      const articleName = articles.find((article) => article.id === id)?.name
      return articleName || ''
    },
    [learnLibraryByIdQuery],
  )

  const [isEditLearnArticleId, handleEditLearnArticleId, handleCloseEditLearnArticleId] =
    useShowControl()

  const handleDelete = useCallback(() => {
    onChange?.(null)
  }, [onChange])

  if (disabled && !isDefined(value)) {
    return null
  }

  return (
    <>
      <UIEntityItemConnection
        linkId={value || undefined}
        text="Link to Learn Library Article"
        articleName={getArticleNameById(value)}
        onEdit={!disabled ? handleEditLearnArticleId : undefined}
        onDelete={!disabled ? handleDelete : undefined}
      />

      <LinkToLearnLibraryModal
        isOpen={isEditLearnArticleId}
        handleClose={handleCloseEditLearnArticleId}
        value={value || null}
        customTableRequest={learnLibraryRequest}
        onSubmit={(newValue) => {
          onChange?.(newValue)
          handleCloseEditLearnArticleId()
        }}
      />
    </>
  )
}
