import { Box, Button, Tab, Tabs } from '@mui/material'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import {
  deleteQuestion,
  duplicateQuestion,
  getByIdQuestion,
  getQuestions,
  updateQuestion,
} from 'api/question'
import columnRenderers, { PLACEHOLDER } from 'components/column-renderers'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import PageTitle from 'components/page/PageTitle'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { getTitle } from 'components/tabs/TabsTitle'
import { reactDomLinkStyles } from 'const'
import {
  columnCreatedBy,
  columnUpdatedBy,
  getActionColumn,
  RowAction,
  UIContentIcons,
  useDuplicateControl,
} from 'features/UI'
import { useGridControl } from 'hooks/useGridControl'
import { useRefreshTable } from 'hooks/useRefreshTable'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import { useShowControl } from 'hooks/useShowControl'
import React, { useCallback, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { routes } from 'routes/routes'
import { BaseTableRequest, EQuestionType, FilterModel, Question, TableResponse } from 'types'
import { getRoute, useGetDataSource, withIdParam } from 'utils'
import { QuestionModalCreate } from '../QuestionModalCreate/QuestionModalCreate'
import moment from 'moment'
import { useExportCSV } from 'hooks/useExportCSV'
import { useQuestionCSVField } from 'features/Questions/hooks/useQuestionCSVField'
import { Metadata } from 'features/Metadata'
import { useQuestionMetadata } from 'features/Questions/hooks/useQuestionMetadata'
import ConfirmPublishModal from 'components/modals/ConfirmPublishModal'
import { PublishAction, usePublishModalControl } from 'hooks/usePublishModalControl'
import NotificationSys from 'components/NotificationSystem'
import { linkedObjectsCellRenderer } from 'features/Questions/helpers/linkedObjectsCellRenderer'

const questionTabTitle = {
  [EQuestionType.question]: {
    pageTitle: 'Quiz/Exam Questions Overview',
    title: 'Quiz/Exam Questions',
  },
  [EQuestionType.query]: {
    pageTitle: 'Query Questions Overview',
    title: 'Query Questions',
  },
}

export const QuestionTablePage = () => {
  const navigate = useNavigate()
  const { type: selectedTab = EQuestionType.question } = useParams<{ type: EQuestionType }>()
  const [questionResponse, setQuestionResponse] = useState<TableResponse<Question>>()

  const handleChangeSelectedTab = useCallback(
    (_: React.SyntheticEvent, newValue: EQuestionType) => {
      const route = getRoute(routes.questions, { type: newValue })
      navigate(route)
    },
    [navigate],
  )

  const getQuestionsWrapper = useCallback(
    (params: BaseTableRequest) => {
      const baseFilter: FilterModel = params.filter || {}
      const userFilter: FilterModel = {
        ...baseFilter,
        type: {
          type: 'text',
          op: 'equals',
          value: selectedTab,
        },
      }
      return getQuestions({
        ...params,
        filter: userFilter,
      }).then((res) => {
        setQuestionResponse(res)
        return res
      })
    },
    [selectedTab],
  )

  const getDataSource = useGetDataSource(getQuestionsWrapper)
  const { onGridReady, gridApi, columnApi } = useGridControl(getDataSource)

  const [isOpenAddQuestionModal, openAddQuestionModal, closeAddQuestionModal] = useShowControl()

  useRefreshTable({ params: { selectedTab }, gridApi })

  const { DuplicateModal, setItem: setQuestionToDuplicate } = useDuplicateControl<Question>({
    entityName: 'Question',
    apiMethod: duplicateQuestion,
    routeSuccess: routes.questionEdit,
  })

  const { idToRemove, openDeleteModal, closeDeleteModal, removeLoading, handleConfirmRemove } =
    useRemoveModalControl({
      deleteFunc: deleteQuestion,
      successCallback: () => gridApi?.purgeInfiniteCache(),
      warning: "Can't remove question",
    })

  const onClickEdit = useCallback(
    (id: number) => {
      navigate(withIdParam(routes.questionEdit, id))
    },
    [navigate],
  )

  const onDuplicateClick = useCallback(
    (id: number, props: ICellRendererParams<Question>) => {
      setQuestionToDuplicate(props.data)
    },
    [setQuestionToDuplicate],
  )

  const publishActionRender = useCallback(
    (props: ICellRendererParams<{ isPublished: boolean }>) => {
      const isPublished = props?.data?.isPublished
      return isPublished ? 'Unpublish' : 'Publish'
    },
    [],
  )

  const {
    action,
    idToPublish,
    openPublishModal,
    closePublishModal,
    publishLoading,
    handleConfirmPublish,
  } = usePublishModalControl({
    successCallback: (_: number, action: PublishAction) => {
      const isPublished = action === PublishAction.Publish
      if (isPublished) {
        NotificationSys.showSuccess(`Question is published`)
      } else {
        NotificationSys.showSuccess(`Question is unpublished`)
      }
      gridApi?.purgeInfiniteCache()
    },
    loadFunc: getByIdQuestion,
    updateFunc: updateQuestion,
  })

  const onPublish = useCallback(
    (id: number, props: ICellRendererParams<{ isPublished: boolean }>) => {
      const isPublished = props?.data?.isPublished
      const action = isPublished ? PublishAction.Unpublish : PublishAction.Publish
      openPublishModal(id, action)
    },
    [openPublishModal],
  )

  const actions: RowAction[] = useMemo(() => {
    return [
      {
        name: 'Edit',
        onClick: onClickEdit,
      },
      { name: 'Delete', onClick: openDeleteModal },
      {
        renderName: publishActionRender,
        onClick: onPublish,
      },
      { name: 'Duplicate', onClick: onDuplicateClick },
    ]
  }, [onClickEdit, onDuplicateClick, openDeleteModal, publishActionRender, onPublish])

  const questionCellRenderer = useCallback(
    (props: ICellRendererParams<{ name: string; id: string }>) => {
      const itemId = props?.data?.id
      const link = `${routes.questionEdit}?id=${itemId}`
      return (
        <Link style={reactDomLinkStyles} to={link}>
          {props.data?.name || PLACEHOLDER}
        </Link>
      )
    },
    [],
  )

  const questionContentCellRenderer = useCallback(({ data }: ICellRendererParams<Question>) => {
    const hasAudio = Boolean(
      data?.enQuestionMediaFileId ||
        data?.enExplanationMediaFileId ||
        data?.esQuestionMediaFileId ||
        data?.esExplanationMediaFileId,
    )
    return <UIContentIcons hasAudio={hasAudio} showPublished={false} />
  }, [])

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Question Name',
        cellRenderer: questionCellRenderer,
        filter: 'agTextColumnFilter',
        colId: 'name',
      },
      {
        headerName: 'Published',
        cellRenderer: columnRenderers.published,
        colId: 'isPublished',
      },
      {
        headerName: 'Content',
        cellRenderer: questionContentCellRenderer,
        colId: 'id',
        sortable: false,
      },
      {
        headerName: selectedTab === EQuestionType.question ? 'Lessons' : 'Queries',
        cellRenderer: linkedObjectsCellRenderer,
        colId: selectedTab === EQuestionType.question ? 'quizLessons' : 'queries',
        sortable: false,
      },
      {
        headerName: 'Creation Date',
        cellRenderer: columnRenderers.createdAt,
        colId: 'createdAt',
      },
      columnCreatedBy(),
      {
        headerName: 'Last Edited',
        cellRenderer: columnRenderers.updatedAt,
        minWidth: 220,
        colId: 'updatedAt',
        sort: 'desc',
      },
      columnUpdatedBy(),
      { ...getActionColumn(actions) },
    ]
  }, [actions, selectedTab, questionCellRenderer, questionContentCellRenderer])

  const left = (
    <>
      <Tabs value={selectedTab} onChange={handleChangeSelectedTab} indicatorColor="primary">
        <Tab value={EQuestionType.question} label={getTitle('Quiz / Exam')} />
        <Tab value={EQuestionType.query} label={getTitle('Queries')} />
      </Tabs>
    </>
  )

  const csvFields = useQuestionCSVField(selectedTab)

  const { csvLoading, onExportCSV } = useExportCSV(
    getQuestionsWrapper,
    gridApi,
    columnApi,
    csvFields,
    `${questionTabTitle[selectedTab].pageTitle}_${moment().format('YYYY-MM-DD')}.csv`,
  )

  const metadata = useQuestionMetadata(questionTabTitle[selectedTab].title, questionResponse)

  return (
    <>
      <PageTitle>{questionTabTitle[selectedTab].pageTitle}</PageTitle>

      <TableToolbar left={left} csvLoading={csvLoading} exportCSVEnable onExportCSV={onExportCSV}>
        <Button variant="outlined" sx={{ minWidth: 120 }} onClick={openAddQuestionModal}>
          Add Question
        </Button>
      </TableToolbar>

      <Box sx={{ mb: 2 }}>
        <Metadata data={metadata} />
      </Box>

      <InfinityAgGrid pagination columnDefs={columnDefs} onGridReady={onGridReady} />

      <QuestionModalCreate isOpen={isOpenAddQuestionModal} onClose={closeAddQuestionModal} />

      <ConfirmRemoveModal
        entityToRemove="Question"
        loading={removeLoading}
        isOpen={idToRemove !== null}
        handleConfirm={handleConfirmRemove}
        handleClose={closeDeleteModal}
      />

      {DuplicateModal}

      <ConfirmPublishModal
        loading={publishLoading}
        handleClose={closePublishModal}
        isOpen={idToPublish !== null}
        handleConfirm={handleConfirmPublish}
        action={action}
        entity="Question"
      />
    </>
  )
}
