import { ICellRendererParams } from 'ag-grid-community'
import { PLACEHOLDER } from 'components/column-renderers'
import { QueryQuestionsCell } from '../components/QueryTablePage/QueryQuestionsCell'
import { Question } from 'types'

export function questionsCellRenderer(props: ICellRendererParams<{ questions: Question[] }>) {
  const questions = props.data?.questions
  if (questions?.length) {
    return <QueryQuestionsCell questions={questions} />
  }
  return PLACEHOLDER
}
