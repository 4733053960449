import { Box, Button, CircularProgress, LinearProgress, linearProgressClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
import { addQuestionnaireEngagementMetric } from 'api/metrics'
import { completeQuestionnaire, getQuestionnaireWithLastResultsById } from 'api/questionaries'
import { useAuthContext } from 'components/context/AuthProvider'
import NotificationSys from 'components/NotificationSystem'
import { minButtonWidth } from 'const'
import { reorderQuestions } from 'models/exam.model'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import {
  IMetricScope,
  IMetricType,
  LessonAnsweredQuestion,
  Questionnaire,
  QuestionnaireResultData,
  QuestionnaireResultLessonToComplete,
} from 'types'
import { formatMetricDate } from 'utils'
import ExplanationScreen from '../../common/explanation-screen'
import QuestionScreen from '../../question-screen'
import QuestionnaireFooter from '../../QuestionnaireFooter'
import { QuestionnaireMetric } from '../QuestionnaireMetric'
import FinalScreen from '../summary'
import FirstScreenQuestionnaire from './intro'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 16,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '005B8C',
  },
}))

interface Props {
  id: number
  handleClose: () => void
  onLoadingStart: () => void
  onLoadingFail: () => void
  onLoadingFinish: () => void
  onQuizTaken?: () => void
}

const getProgress = (stage: number, max: number) => {
  if (stage === FIRST_SCREEN) {
    return 0
  }
  if (stage === LAST_SCREEN) {
    return 100
  }
  return ((stage + 1) / (max + 2)) * 100
}

const FIRST_SCREEN = -1
const LAST_SCREEN = -2

const ModalContent = (props: Props) => {
  const { handleClose } = props

  const { user } = useAuthContext()
  const [stage, setStage] = useState(FIRST_SCREEN)
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>()
  const [answers, setAnswers] = useState<Record<string, LessonAnsweredQuestion>>({})
  const [results, setResults] = useState<QuestionnaireResultData>()
  const [lessonsToComplete, setLessonsToComplete] = useState<QuestionnaireResultLessonToComplete[]>(
    [],
  )

  const saveResults = useMutation(completeQuestionnaire, {
    onSuccess: (response) => {
      setResults(response.data)
      NotificationSys.showSuccess(
        'Your score for this exam is recorded in your Results list in Progress',
      )
      props.onQuizTaken && props.onQuizTaken()
    },
  })

  useEffect(() => {
    props.onLoadingStart()

    getQuestionnaireWithLastResultsById({ id: props.id })
      .then(async (response) => {
        const { questionnaire, results, lessonsToComplete } = response.data
        setQuestionnaire(questionnaire)
        setLessonsToComplete(lessonsToComplete)
        if (results) {
          setResults(results)
          const isPassed = results.status === 'passed'
          // if failed and there are no lessons to review -> retake
          const isFailed = results.status === 'failed' && results.lessonsToReview.length > 0
          if (isPassed || isFailed) {
            setStage(LAST_SCREEN)
          }
        } else {
          if (lessonsToComplete.length > 0) {
            setStage(LAST_SCREEN)
          }
        }
        props.onLoadingFinish()
      })
      .catch(() => props.onLoadingFail)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const finish = () => {
    return saveResults.mutateAsync({
      answers: Object.values(answers),
      id: props.id,
    })
  }

  const question = useMemo(() => {
    const index = Math.floor(stage / 2)
    return questionnaire?.questions[index]
  }, [questionnaire, stage])

  const questionAnswered = useCallback(
    (answer: string) => {
      if (!question) {
        return
      }

      const result = { ...answers }
      result[question.id] = {
        answer,
        questionId: question.id,
      }
      setAnswers(result)
    },
    [answers, question],
  )

  const maxProgress = useMemo(() => {
    if (!questionnaire) {
      return -1
    }
    return questionnaire.questions.length * 2 - 1
  }, [questionnaire])

  const answer = useMemo(() => {
    if (question) {
      return answers[question?.id]
    }
  }, [answers, question])

  const stageType = useMemo(() => {
    if (stage == FIRST_SCREEN) {
      return 'intro'
    }

    if (stage == LAST_SCREEN) {
      return 'summary'
    }

    if (stage % 2 == 1) {
      return 'explanation'
    }

    return 'question'
  }, [stage])

  const onClickNext = async () => {
    if (stage === LAST_SCREEN) {
      handleClose()
    } else if (stage === maxProgress) {
      await finish()
      setStage(LAST_SCREEN)
    } else {
      setStage(stage + 1)
    }
  }

  const onClickPrevious = () => {
    if (stage === LAST_SCREEN) {
      setStage(maxProgress)
    } else {
      setStage(stage - 1)
    }
  }

  const isPreviousVisible = stage !== FIRST_SCREEN && stage !== LAST_SCREEN

  const handleRetake = () => {
    setQuestionnaire((prevData) => {
      return prevData ? (reorderQuestions(prevData) as Questionnaire) : prevData
    })
    setStage(FIRST_SCREEN)
    setAnswers({})
  }

  const handleSaveAudioMetric = async (value: number, uuid: string, scope: IMetricScope) => {
    if (!user || !questionnaire) {
      return
    }

    await addQuestionnaireEngagementMetric({
      userId: user.id,
      questionnaireId: questionnaire.id,
      scope,
      engagedFrom: formatMetricDate(value),
      engagedTo: formatMetricDate(),
    })
  }

  if (!questionnaire) {
    return null
  }

  const renderStage = () => {
    if (stageType == 'intro')
      return (
        <FirstScreenQuestionnaire
          handleClose={handleClose}
          intro={questionnaire.intro}
          name={questionnaire.name}
        />
      )

    if (stageType == 'summary')
      return (
        <FinalScreen
          results={results}
          lessonsToComplete={lessonsToComplete}
          retake={handleRetake}
          handleClose={handleClose}
          name={questionnaire.name}
        />
      )

    if (!question) {
      return null
    }

    if (stageType == 'explanation') {
      return (
        <ExplanationScreen
          answer={answer}
          explanation={question.explanation}
          handleClose={handleClose}
          title={question.name}
          question={question.question}
          correctAnswer={question.correctAnswer}
          enExplanationMediaUrl={question.enExplanationMediaUrl}
          esExplanationMediaUrl={question.esExplanationMediaUrl}
        />
      )
    }

    return (
      <QuestionScreen
        answer={answer?.answer}
        onChange={questionAnswered}
        metricType={IMetricType.questionnaire}
        metricEntityId={questionnaire.id}
        onSaveAudioMetric={handleSaveAudioMetric}
        handleClose={handleClose}
        question={question}
      />
    )
  }

  return (
    <>
      <Box sx={{ mb: 4, flex: 1 }}>{renderStage()}</Box>
      <QuestionnaireMetric questionnaire={questionnaire} />

      <Box>
        {stage !== LAST_SCREEN && (
          <BorderLinearProgress variant="determinate" value={getProgress(stage, maxProgress)} />
        )}

        <QuestionnaireFooter
          leftButton={
            <Button
              variant="outlined"
              onClick={onClickPrevious}
              sx={minButtonWidth}
              disabled={stageType === 'explanation'}
            >
              {'< Previous'}
            </Button>
          }
          rightButton={
            <NextButton
              lastScreen={stage == LAST_SCREEN}
              onClickNext={onClickNext}
              saving={saveResults.isLoading}
              disabled={stageType == 'question' && !answer?.answer}
            />
          }
          isPreviousVisible={isPreviousVisible}
          isNextVisible={true}
        />
      </Box>
    </>
  )
}

type NextButtonProps = {
  onClickNext: () => void
  saving: boolean
  lastScreen: boolean
  disabled: boolean
}

const NextButton = ({ saving, onClickNext, lastScreen, disabled }: NextButtonProps) => {
  if (lastScreen) {
    return null
  }

  return (
    <Button
      variant="outlined"
      onClick={onClickNext}
      sx={minButtonWidth}
      disabled={saving || disabled}
    >
      {!saving ? 'Next >' : null}
      {saving ? <CircularProgress size="1rem" /> : null}
    </Button>
  )
}

export default ModalContent
