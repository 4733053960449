import {
  BaseTableRequest,
  Query,
  QueryRange,
  QueryRangeCreate,
  QueryRangeOrderUpdate,
  QueryRangeUpdate,
  QueryRequestComplete,
  QueryRequestCreate,
  QueryRequestPublish,
  QueryRequestUpdate,
  QueryResult,
  RequestById,
  Response,
  TableResponse,
} from 'types'
import axiosInstance from './axios'

export const getQuery = async (request: BaseTableRequest): Promise<TableResponse<Query>> => {
  return axiosInstance.post('/queries', request)
}

export const createQuery = async (request: QueryRequestCreate): Promise<Response<Query>> => {
  return axiosInstance.post('/queries/create', request)
}

export const getQueryById = async (request: RequestById): Promise<Response<Query>> => {
  return axiosInstance.post('/queries/read', request)
}

export const updateQuery = async (
  request: Partial<QueryRequestUpdate>,
): Promise<Response<Query>> => {
  return axiosInstance.post('/queries/update', request)
}

export const publishQuery = async (request: QueryRequestPublish): Promise<Response<Query>> => {
  return axiosInstance.post('/queries/update', request)
}

export const deleteQuery = async (request: RequestById): Promise<unknown> => {
  return axiosInstance.post('/queries/delete', request)
}

export const getQueryResults = (id: number): Promise<Response<QueryResult[]>> => {
  return axiosInstance.post('/queries/results', { id })
}

export const completeQuery = (
  request: QueryRequestComplete,
): Promise<Response<Partial<QueryResult>>> => {
  return axiosInstance.post('/queries/complete', request)
}

export const createQueryRange = (request: QueryRangeCreate): Promise<Response<QueryRange>> => {
  return axiosInstance.post('/result-ranges/create', request)
}

export const updateQueryRange = (request: QueryRangeUpdate): Promise<Response<QueryRange>> => {
  return axiosInstance.post('/result-ranges/update', request)
}

export const deleteQueryRange = (request: RequestById): Promise<void> => {
  return axiosInstance.post('/result-ranges/delete', request)
}

export const saveQueryRangeOrder = (request: QueryRangeOrderUpdate): Promise<void> => {
  return axiosInstance.post('/queries/order-result-ranges', request)
}

export const checkQueryCanPublish = async (request: RequestById): Promise<void> => {
  return axiosInstance.post('/queries/can-publish', request)
}
