import { Box, Stack } from '@mui/material'
import { UIItemContentContainer, UIStatusBadge } from 'features/UI'
import { ReactNode } from 'react'
import { ETimelinePostStatus } from 'types'
import { TimelineItemHeader } from '../TimelineItemHeader/TimelineItemHeader'

export function TimelineItemCard({
  title,
  status,
  controls,
  bannerUrl,
  onClick,
}: {
  controls: ReactNode | null
  title: string
  status: ETimelinePostStatus
  bannerUrl?: string | null
  onClick: () => void
}) {
  return (
    <UIItemContentContainer onClick={onClick}>
      <TimelineItemHeader title={title} showAvatar bannerUrl={bannerUrl} hideBorderBottom />

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <UIStatusBadge status={status} />

        <Box minWidth="64px">{controls}</Box>
      </Stack>
    </UIItemContentContainer>
  )
}
