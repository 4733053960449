import { useMemo } from 'react'
import { CSVField } from 'hooks/useExportCSV'
import { csvColumnCreatedBy, csvColumnUpdatedBy } from 'features/UI'
import { formatDate } from 'utils'
import { EQuestionType } from 'types'

export function useQuestionCSVField(questionType: EQuestionType) {
  return useMemo<CSVField[]>(
    () => [
      {
        label: 'Question name',
        value: 'name',
      },
      {
        label: 'Published',
        value: ({ isPublished }) => (isPublished ? 'Published' : 'Unpublished'),
      },
      {
        label: questionType === EQuestionType.question ? 'Lessons' : 'Queries',
        value: ({ quizLessons, queries }) => {
          const linkedObjects = questionType === EQuestionType.question ? quizLessons : queries
          return linkedObjects.map((o: { name: string }) => o.name).join(', ')
        },
      },
      {
        label: 'Creation Date',
        value: ({ createdAt }) => formatDate(createdAt),
      },
      csvColumnCreatedBy(),
      {
        label: 'Last Edited',
        value: ({ updatedAt }) => formatDate(updatedAt),
      },
      csvColumnUpdatedBy(),
    ],
    [questionType],
  )
}
