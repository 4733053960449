import { Button, Stack, Typography } from '@mui/material'
import { saveQueryRangeOrder } from 'api/query'
import NotificationSys from 'components/NotificationSystem'
import { useShowControl } from 'hooks/useShowControl'
import flatten from 'lodash/flatten'
import isEqual from 'lodash/isEqual'
import uniq from 'lodash/uniq'
import React, { useCallback, useMemo, useState } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { QueryRange } from 'types'
import { getDragDropListStyle, reorder } from 'utils'
import { fillRange } from '../../models/QueryRange.model'
import { QueryRangeItem } from '../QueryRangeItem/QueryRangeItem'
import { QueryRangeModalForm } from '../QueryRangeModalForm/QueryRangeModalForm'

export function QueryRanges({
  queryId,
  minPoints,
  maxPoints,
  ranges,
  onReloadQuery,
  onDragEnd,
}: {
  minPoints: number
  maxPoints: number
  queryId: number
  ranges: QueryRange[]
  onReloadQuery: () => Promise<void>
  onDragEnd: (ranges: QueryRange[]) => void
}) {
  const [isOpenRangeForm, onOpenRangeForm, onCloseRangeForm] = useShowControl()

  const [rangeForEdit, setRangeForEdit] = useState<QueryRange | null>(null)

  const handleEdit = useCallback(
    (range: QueryRange) => {
      setRangeForEdit(range)
      onOpenRangeForm()
    },
    [onOpenRangeForm],
  )

  const handleClose = useCallback(() => {
    setRangeForEdit(null)
    onCloseRangeForm()
  }, [onCloseRangeForm])

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      try {
        if (!result.destination) return

        const reorderedRanges = reorder(ranges, result.source.index, result.destination.index)

        const originalOrder = ranges.map((range) => range.id)
        const order = reorderedRanges.map((range) => range.id)

        onDragEnd(reorderedRanges)

        if (isEqual(originalOrder, order)) return

        await saveQueryRangeOrder({
          id: queryId,
          resultRanges: order,
        })

        NotificationSys.showSuccess('The ranges order was saved')
      } catch (e) {
        NotificationSys.showWarning('An error occurred while saving the ranges order')
      }
    },
    [onDragEnd, queryId, ranges],
  )

  const hasGaps = useMemo(() => {
    if (minPoints === 0 && maxPoints === 0) return false // no questions
    if (ranges.length === 0) return false // no ranges

    const filledRange = fillRange(minPoints, maxPoints)
    const points = ranges.map((rangeItem) => fillRange(rangeItem.pointsFrom, rangeItem.pointsTo))
    const flattenPoints = uniq(flatten(points)).filter(
      (point) => point >= minPoints && point <= maxPoints,
    )
    return filledRange.length !== flattenPoints.length
  }, [maxPoints, minPoints, ranges])

  const isOutOfRanges = useMemo(() => {
    const hasRange = ranges.find(
      (range) => range.pointsFrom < minPoints || range.pointsTo > maxPoints,
    )
    return !!hasRange
  }, [maxPoints, minPoints, ranges])

  const hasOverlap = useMemo(() => {
    const points = ranges.map((rangeItem) => fillRange(rangeItem.pointsFrom, rangeItem.pointsTo))

    const flattenArray = flatten(points)
    const uniqueValues = uniq(flattenArray)

    const hasDuplicates = flattenArray.length !== uniqueValues.length
    return hasDuplicates
  }, [ranges])

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
        <Typography variant="h6" fontWeight={400}>
          Result Ranges
        </Typography>
        <Button variant="contained" onClick={onOpenRangeForm}>
          Add Result Range
        </Button>
      </Stack>

      {ranges.length === 0 && <Typography>No result ranges</Typography>}

      {hasGaps && <Typography color="red">There is a gap in range values. Please check</Typography>}

      {isOutOfRanges && (
        <Typography color="red">
          You have added/ deleted a question. Please update the ranges in the result panels.
        </Typography>
      )}

      {hasOverlap && (
        <Typography color="red">There are overlapping values. Please update them.</Typography>
      )}

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppableRanges">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getDragDropListStyle(snapshot.isDraggingOver)}
            >
              {ranges.map((range, index) => {
                return (
                  <QueryRangeItem
                    key={range.id}
                    range={range}
                    index={index}
                    onEdit={handleEdit}
                    onReloadQuery={onReloadQuery}
                  />
                )
              })}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <QueryRangeModalForm
        range={rangeForEdit}
        ranges={ranges}
        isOpen={isOpenRangeForm}
        minPoints={minPoints}
        maxPoints={maxPoints}
        queryId={queryId}
        onClose={handleClose}
        onReloadQuery={onReloadQuery}
      />
    </Stack>
  )
}
