import { Box, Button, CircularProgress, LinearProgress, linearProgressClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
import { addLessonEngagementMetric } from 'api/metrics'
import { completeQuiz, getQuizWithLastResultsById } from 'api/quiz'
import { useAuthContext } from 'components/context/AuthProvider'
import NotificationSys from 'components/NotificationSystem'
import { minButtonWidth } from 'const'
import { useTimelineItemStatus } from 'features/Timeline/hooks/useTimelineItemStatus'
import { reorderQuestions } from 'models/exam.model'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { selectMetricIds } from 'store/slice/metric.slice'
import {
  ETimelinePostType,
  IMetricScope,
  IMetricType,
  LessonAnsweredQuestion,
  Quiz,
  QuizResultData,
} from 'types'
import { formatMetricDate } from 'utils'
import ExplanationScreen from '../../common/explanation-screen'
import QuestionScreen from '../../question-screen'
import QuestionnaireFooter from '../../QuestionnaireFooter'
import { QuizMetric } from '../QuizMetric'
import FinalScreen from '../summary'
import FirstScreenQuestionnaire from './intro'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 16,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '005B8C',
  },
}))

interface Props {
  id: number
  handleClose: () => void
  onLoadingStart: () => void
  onLoadingFail: () => void
  onLoadingFinish: () => void
  onQuizTaken?: () => void
}

const getProgress = (stage: number, max: number) => {
  if (stage === FIRST_SCREEN) {
    return 0
  }
  if (stage === LAST_SCREEN) {
    return 100
  }
  return ((stage + 1) / (max + 2)) * 100
}

const FIRST_SCREEN = -1
const LAST_SCREEN = -2

const ModalContent = (props: Props) => {
  const { handleClose } = props

  const { user } = useAuthContext()
  const [stage, setStage] = useState(FIRST_SCREEN)
  const [quiz, setQuiz] = useState<Quiz>()
  const [answers, setAnswers] = useState<Record<string, LessonAnsweredQuestion>>({})
  const [results, setResults] = useState<QuizResultData>()

  const savedMetricIds = useSelector(selectMetricIds(IMetricType.lesson))

  const saveResults = useMutation(completeQuiz, {
    onSuccess: (response) => {
      setResults(response.data)
      NotificationSys.showSuccess(
        'Your score for this lesson is recorded in your Results list in Progress',
      )
      props.onQuizTaken && props.onQuizTaken()
    },
  })

  useEffect(() => {
    props.onLoadingStart()

    getQuizWithLastResultsById({ id: props.id })
      .then(async (response) => {
        const { quiz, results } = response.data
        setQuiz(quiz)
        if (results) {
          setResults(results)
          const isPassed = results.status === 'passed'
          if (isPassed) {
            setStage(LAST_SCREEN)
          }
        }

        props.onLoadingFinish()
      })
      .catch(() => props.onLoadingFail)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const finishQuiz = () => {
    return saveResults.mutateAsync({
      answers: Object.values(answers),
      id: props.id,
      lessonUuid: savedMetricIds?.uuid || undefined,
    })
  }

  const question = useMemo(() => {
    const index = Math.floor(stage / 2)
    return quiz?.questions[index]
  }, [quiz, stage])

  const questionAnswered = useCallback(
    (answer: string) => {
      if (!question) {
        return
      }

      const result = { ...answers }
      result[question.id] = {
        answer,
        questionId: question.id,
      }
      setAnswers(result)
    },
    [answers, question],
  )

  const maxProgress = useMemo(() => {
    if (!quiz) {
      return -1
    }
    return quiz.questions.length * 2 - 1
  }, [quiz])

  const answer = useMemo(() => {
    if (question) {
      return answers[question?.id]
    }
  }, [answers, question])

  const stageType = useMemo(() => {
    if (stage == FIRST_SCREEN) {
      return 'intro'
    }

    if (stage == LAST_SCREEN) {
      return 'summary'
    }

    if (stage % 2 == 1) {
      return 'explanation'
    }

    return 'question'
  }, [stage])

  const percentCorrect = useMemo(() => {
    if (!results) {
      return 0
    }

    const percent = (results.correctAnswerCount * 100) / results.answeredQuestions.length
    return Math.round(percent)
  }, [results])

  const onClickNext = async () => {
    if (stage === LAST_SCREEN) {
      handleClose()
    } else if (stage === maxProgress) {
      await finishQuiz()
      setStage(LAST_SCREEN)
    } else {
      setStage(stage + 1)
    }
  }

  const onClickPrevious = () => {
    if (stage === LAST_SCREEN) {
      setStage(maxProgress)
    } else {
      setStage(stage - 1)
    }
  }

  const isPreviousVisible = stage !== FIRST_SCREEN && stage !== LAST_SCREEN

  const retakeQuiz = () => {
    setQuiz((prevQuiz) => {
      return prevQuiz ? (reorderQuestions(prevQuiz) as Quiz) : prevQuiz
    })
    setStage(FIRST_SCREEN)
    setAnswers({})
  }

  const handleSaveAudioMetric = async (value: number, uuid: string, scope: IMetricScope) => {
    if (!user || !quiz) {
      return
    }

    await addLessonEngagementMetric({
      lessonUuid: uuid,
      lessonId: quiz.lessonId,
      courseId: quiz.lesson.courseId,
      userId: user.id,
      engagedFrom: formatMetricDate(value),
      engagedTo: formatMetricDate(),
      scope,
    })
  }

  useTimelineItemStatus({
    id: quiz?.lessonId,
    itemType: ETimelinePostType.lessons,
  })

  if (!quiz) {
    return null
  }

  const renderStage = () => {
    if (stageType == 'intro')
      return (
        <FirstScreenQuestionnaire handleClose={handleClose} intro={quiz.intro} name={quiz.name} />
      )

    if (stageType == 'summary')
      return (
        <FinalScreen
          retakeQuiz={retakeQuiz}
          handleClose={handleClose}
          percentCorrect={percentCorrect}
          lessonId={quiz.lessonId}
        />
      )

    if (!question) {
      return null
    }

    if (stageType == 'explanation') {
      return (
        <ExplanationScreen
          answer={answer}
          explanation={question.explanation}
          handleClose={handleClose}
          title={question.name}
          question={question.question}
          correctAnswer={question.correctAnswer}
          enExplanationMediaUrl={question.enExplanationMediaUrl}
          esExplanationMediaUrl={question.esExplanationMediaUrl}
        />
      )
    }

    return (
      <QuestionScreen
        answer={answer?.answer}
        onChange={questionAnswered}
        metricType={IMetricType.lesson}
        metricEntityId={quiz.lessonId}
        onSaveAudioMetric={handleSaveAudioMetric}
        handleClose={handleClose}
        question={question}
      />
    )
  }

  return (
    <>
      <Box sx={{ mb: 4, flex: 1 }}>{renderStage()}</Box>
      <QuizMetric quiz={quiz} />

      <Box>
        {stage !== LAST_SCREEN && (
          <BorderLinearProgress variant="determinate" value={getProgress(stage, maxProgress)} />
        )}

        <QuestionnaireFooter
          leftButton={
            <Button
              variant="outlined"
              onClick={onClickPrevious}
              sx={minButtonWidth}
              disabled={stageType === 'explanation'}
            >
              {'< Previous'}
            </Button>
          }
          rightButton={
            <NextButton
              lastScreen={stage == LAST_SCREEN}
              onClickNext={onClickNext}
              saving={saveResults.isLoading}
              disabled={stageType == 'question' && !answer?.answer}
            />
          }
          isPreviousVisible={isPreviousVisible}
          isNextVisible={true}
        />
      </Box>
    </>
  )
}

type NextButtonProps = {
  onClickNext: () => void
  saving: boolean
  lastScreen: boolean
  disabled: boolean
}

const NextButton = ({ saving, onClickNext, lastScreen, disabled }: NextButtonProps) => {
  if (lastScreen) {
    return null
  }

  return (
    <Button
      variant="outlined"
      onClick={onClickNext}
      sx={minButtonWidth}
      disabled={saving || disabled}
    >
      {!saving ? 'Next >' : null}
      {saving ? <CircularProgress size="1rem" /> : null}
    </Button>
  )
}

export default ModalContent
