import React from 'react'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller'
import { ImageType } from 'react-images-uploading/dist/typings'
import ImageUpload from './ImageUpload'

const FormImageUpload = ({
  name,
  hasRatioRestrictions,
}: {
  name: string
  hasRatioRestrictions?: boolean
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const handleChange =
    (field: ControllerRenderProps<FieldValues, string>) => async (imgData: ImageType) => {
      field.onChange(imgData)
    }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={null}
      render={({ field }) => (
        <ImageUpload
          onSave={handleChange(field)}
          imgData={field.value}
          error={(errors[name] ? errors[name]?.message : null) as string}
          hasRatioRestrictions={hasRatioRestrictions}
        />
      )}
    />
  )
}

export default FormImageUpload
