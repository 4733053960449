import { Option } from 'components/form-elements/FormSelect'
import ModalWithSelect from 'components/modals/simple-modals/ModalWithSelect'
import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { TableResponse } from 'types'

export function UIModalSelectEntity<T extends { id: number; name: string }>({
  queryId,
  parentEntityId,
  apiRequest,
  title,
  label,
  description,
  onClose,
  loading,
  value,
  onSubmit,
}: {
  queryId: string
  parentEntityId: number
  apiRequest: () => Promise<TableResponse<T>>
  title: string
  label: string
  onClose: () => void
  description?: string
  value?: number
  onSubmit: (id: number) => void
  loading: boolean
}) {
  const items = useQuery(queryId, () => apiRequest())
  const options = useMemo(() => {
    if (!items.data) {
      return []
    }

    const options = [...items.data.data.rows]
      .map<Option>((item) => ({ label: item.name, value: item.id }))
      .filter((item) => item.value !== parentEntityId)
    options.sort((a, b) => (a.label > b.label ? 1 : -1))

    return options
  }, [items, parentEntityId])

  return (
    <ModalWithSelect
      value={value}
      title={title}
      label={label}
      description={description}
      buttonText="Save"
      isOpen
      handleClose={onClose}
      onSubmit={onSubmit}
      options={options}
      loading={loading}
      loadingOptions={items.isLoading}
    />
  )
}
