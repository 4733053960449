import React, { useCallback, useMemo, useState } from 'react'
import { useAppDispatch } from 'store/hooks'
import { metricClearUuid } from 'store/slice/metric.slice'
import { IMetricType } from 'types/metric.types'
import TakeQuery from './query/content'
import ViewQueryResults from './query/view-results/content'
import QuestionWizardModal from './question-wizard-modal'
import TakeQuestionnaire from './questionnaire/take/content'
import ViewQuestionnaireResults from './questionnaire/view/content'
import TakeQuiz from './quiz/take-quiz/content'
import ViewQuizResults from './quiz/view-results/content'

export type Props = {
  id: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraParams: any
  handleClose: () => void
  type: 'quiz' | 'query' | 'questionnaire'
  mode: 'take' | 'view_results'
  onQuestioningTaken?: () => void
}

/**
 * Facade for quiz, query and questionnaire
 * @param props
 * @returns
 */
const QuestionWizard = (props: Props) => {
  const { handleClose } = props
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [failedLoading, setFailedLoading] = useState(false)
  const [type] = useState(props.type)
  const [mode, setMode] = useState(props.mode)
  const [quizId] = useState(props.id)

  const loadingFailed = useCallback(() => {
    setFailedLoading(true)
  }, [])

  const loadingFinished = useCallback(() => {
    setLoading(false)
  }, [])

  const loadingStart = useCallback(() => {
    setLoading(true)
  }, [])

  const retakeQuestioning = useCallback(() => {
    setMode('take')
  }, [])

  const onClearMetricUuid = useCallback(() => {
    // query has 2 metrics
    if (type === 'query') {
      dispatch(metricClearUuid({ type: IMetricType.query }))
      dispatch(metricClearUuid({ type: IMetricType.learnArticle }))
      return
    }

    let metricType: IMetricType = IMetricType.lesson
    if (type === 'quiz') {
      metricType = IMetricType.lesson
    } else if (type === 'questionnaire') {
      metricType = IMetricType.questionnaire
    }

    if (!metricType) {
      return
    }

    dispatch(metricClearUuid({ type: metricType }))
  }, [dispatch, type])

  const onClose = useCallback(() => {
    onClearMetricUuid()
    handleClose()
  }, [handleClose, onClearMetricUuid])

  const innerControl = useMemo(() => {
    switch (type) {
      case 'quiz':
        switch (mode) {
          case 'take':
            return (
              <TakeQuiz
                id={quizId}
                handleClose={onClose}
                onLoadingFail={loadingFailed}
                onLoadingFinish={loadingFinished}
                onLoadingStart={loadingStart}
                onQuizTaken={props.onQuestioningTaken}
              />
            )
          case 'view_results':
            return (
              <ViewQuizResults
                quizId={quizId}
                completedLessonId={props.extraParams.completedLessonId}
                handleClose={onClose}
                onLoadingFail={loadingFailed}
                onLoadingFinish={loadingFinished}
                onLoadingStart={loadingStart}
                retakeQuiz={retakeQuestioning}
              />
            )
        }
        break
      case 'query': {
        switch (mode) {
          case 'take':
            return (
              <TakeQuery
                id={quizId}
                handleClose={onClose}
                onLoadingFail={loadingFailed}
                onLoadingFinish={loadingFinished}
                onLoadingStart={loadingStart}
                onQuizTaken={props.onQuestioningTaken}
              />
            )

          case 'view_results':
            return (
              <ViewQueryResults
                id={quizId}
                handleClose={onClose}
                onLoadingFail={loadingFailed}
                onLoadingFinish={loadingFinished}
                onLoadingStart={loadingStart}
                answers={props.extraParams.answers}
                learnArticleId={props.extraParams.learnArticleId}
                notificationText={props.extraParams.notificationText}
                retakeQuiz={retakeQuestioning}
              />
            )
        }
      }

      case 'questionnaire': {
        switch (mode) {
          case 'take':
            return (
              <TakeQuestionnaire
                id={props.id}
                handleClose={onClose}
                onLoadingFail={loadingFailed}
                onLoadingFinish={loadingFinished}
                onLoadingStart={loadingStart}
                onQuizTaken={props.onQuestioningTaken}
              />
            )

          case 'view_results':
            return (
              <ViewQuestionnaireResults
                id={props.id}
                completedCourseId={props.extraParams.completedCourseId}
                handleClose={onClose}
                onLoadingFail={loadingFailed}
                onLoadingFinish={loadingFinished}
                onLoadingStart={loadingStart}
                retakeQuiz={retakeQuestioning}
              />
            )
        }
      }
    }
  }, [
    type,
    mode,
    quizId,
    onClose,
    loadingFailed,
    loadingFinished,
    loadingStart,
    props.onQuestioningTaken,
    props.extraParams,
    props.id,
    retakeQuestioning,
  ])

  return (
    <QuestionWizardModal
      isOpen={true}
      handleClose={onClose}
      loading={loading}
      failedLoading={failedLoading}
    >
      {innerControl}
    </QuestionWizardModal>
  )
}

export default QuestionWizard
