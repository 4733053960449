import { Box, Button, Stack } from '@mui/material'
import { useDrawersContext } from 'components/context/ClientsDrawersProvider'
import { UIItemContentContainer, UIItemImage, UIStatusBadge } from 'features/UI'
import IconQuestion from 'images/icons/icon_sm_question.png'
import React, { useCallback } from 'react'
import { useActions } from 'store/hooks'
import { ETimelinePostStatus, ETimelinePostType, QuestioningType } from 'types'
import { TimelineItemHeader } from '../TimelineItemHeader/TimelineItemHeader'
import { TimelineLine } from '../TimelineLine/TimelineLine'
import { setStatus } from 'api/timelines'
import { useMutation } from 'react-query'

export const TimelineQuestioningItem = ({
  id,
  title,
  date,
  type,
  status,
  bannerUrl,
}: {
  title: string
  date: string
  id: number
  type: QuestioningType
  status: ETimelinePostStatus
  bannerUrl: string | null
}) => {
  const { handleOpenQuestioning } = useDrawersContext()
  const { getTimelineById } = useActions()
  const setStatusMutation = useMutation(setStatus)

  const onQuestioningTaken = useCallback(() => {
    getTimelineById({})
  }, [getTimelineById])

  const onClickQuestioning = () => {
    if (status === ETimelinePostStatus.new) {
      setStatusMutation.mutate(
        { id, status: ETimelinePostStatus.review, type: ETimelinePostType.queries },
        {
          onSuccess: onQuestioningTaken,
        },
      )
    }

    handleOpenQuestioning({
      mode: 'take',
      id: id,
      type: type,
      onQuestioningTaken: onQuestioningTaken,
    })
  }

  const buttonText = status === ETimelinePostStatus.done ? 'Retake' : 'Take'

  return (
    <Stack direction="row" spacing={2}>
      <div style={{ position: 'relative', minWidth: 30, minHeight: '100%' }}>
        <UIItemImage src={IconQuestion} isOnTimeline />
        <TimelineLine />
      </div>

      <UIItemContentContainer>
        <TimelineItemHeader
          title={title}
          date={date}
          bannerUrl={bannerUrl}
          hideBorderBottom
          showAvatar={true}
        />
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <UIStatusBadge status={status} />
          <Button size="small" variant="contained" onClick={onClickQuestioning}>
            {buttonText}
          </Button>
        </Stack>
      </UIItemContentContainer>
    </Stack>
  )
}
