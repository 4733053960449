import { getTimelineItemByEntity, setStatus } from 'api/timelines'
import { useEffect, useMemo, useRef } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useActions } from 'store/hooks'
import { ETimelinePostStatus, ETimelinePostType, LessonTimelineItem } from 'types'

export function useTimelineItemStatus({
  id,
  itemType,
}: {
  id?: number
  itemType: ETimelinePostType
}) {
  const { getTimelineSilent } = useActions()
  const setStatusMutation = useMutation(setStatus)
  const isUpdated = useRef(false)

  const isTypeAccepted = useMemo(() => {
    const acceptedTypes = [
      ETimelinePostType.lessons,
      ETimelinePostType.careArticle,
      ETimelinePostType.taskArticle,
    ]

    return acceptedTypes.includes(itemType)
  }, [itemType])

  const { data: timelineItem, isFetched: isTimelineFetched } = useQuery(
    'timelineItem',
    () => {
      if (!id) return

      return getTimelineItemByEntity(id, itemType, {
        suppressErrorHandler: [404],
      })
    },
    {
      enabled: isTypeAccepted && !!id,
    },
  )

  const timelineStatus = useMemo(() => {
    return timelineItem?.data?.status
  }, [timelineItem])

  const quizStatus = useMemo(() => {
    if (itemType === ETimelinePostType.lessons) {
      if (!timelineItem?.data) return null

      const { lesson } = timelineItem.data as LessonTimelineItem
      if (!lesson) return null

      const { completedLessons } = lesson
      if (!completedLessons || completedLessons?.length === 0) return null

      return completedLessons[0].status
    }

    return null
  }, [itemType, timelineItem])

  useEffect(() => {
    if (
      isTimelineFetched &&
      timelineStatus === ETimelinePostStatus.new &&
      !isUpdated.current &&
      isTypeAccepted &&
      id
    ) {
      isUpdated.current = true
      setStatusMutation.mutate(
        {
          id,
          status: ETimelinePostStatus.review,
          type: itemType,
        },
        {
          onSuccess: () => {
            getTimelineSilent()
          },
        },
      )
    }
  }, [
    getTimelineSilent,
    id,
    isTimelineFetched,
    isTypeAccepted,
    itemType,
    setStatusMutation,
    timelineStatus,
  ])

  return { status: timelineStatus, quizStatus }
}
