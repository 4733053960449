import { ColDef, GridReadyEvent } from 'ag-grid-community'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import { UIModal } from 'features/UI'
import { SyntheticEvent, useMemo, useState } from 'react'

export function QuestionLinkedObjectsCell({
  title,
  objects,
}: {
  title: string
  objects: Array<{ id: number; name: string }>
}) {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = (event: SyntheticEvent) => {
    event.preventDefault()
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleGridReady = (event: GridReadyEvent) => {
    event.api.sizeColumnsToFit()
  }

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Name',
        field: 'name',
      },
    ]
  }, [])

  return (
    <div>
      <a href="" onClick={handleOpen}>
        {objects.length}
      </a>

      <UIModal isOpen={isOpen} onClose={handleClose} width={648} title={title}>
        <div
          className="ag-theme-alpine"
          style={{ height: '400px', width: '600px', fontSize: '1rem' }}
        >
          <InfinityAgGrid
            rowData={objects}
            columnDefs={columnDefs}
            rowModelType="clientSide"
            onGridReady={handleGridReady}
            floatingFiltersHeight={0}
          />
        </div>
      </UIModal>
    </div>
  )
}
