import ScrollContainer from 'components/page/ScrollContainer'
import { heightOfToolbar } from 'const'
import { UICollapseProvider } from 'features/UI'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppSelector } from 'store/hooks'
import { selectTimelineFiltersTypes } from 'store/slice/timelineFilters.slice'
import { ArticleType, ETimelinePostType, TimelineItem } from 'types'
import { useTimelineList } from '../../hooks/useTimelineList'
import { TimelineArticleItem } from '../TimelineArticleItem/TimelineArticleItem'
import { TimelineCourseItem } from '../TimelineCourseItem/TimelineCourseItem'
import { TimelineDateItem } from '../TimelineDateItem/TimelineDateItem'
import { TimelineNoteItem } from '../TimelineNoteItem/TimelineNoteItem'
import { TimelineQuestioningItem } from '../TimelineQuestioningItem/TimelineQuestioningItem'

export function TimelineList({ timeline }: { timeline: TimelineItem[] }) {
  const userCreatedAt = useAppSelector((state) => state.currentUser.createdAt)
  const timelineTypes = useSelector(selectTimelineFiltersTypes)

  const { timelineElements } = useTimelineList(timeline)

  const isAllCoursesExpanded = useMemo(
    () => timelineTypes?.includes(ETimelinePostType.lessons) || false,
    [timelineTypes],
  )

  return (
    <ScrollContainer deltaSpace={heightOfToolbar} fixLeft>
      <UICollapseProvider>
        <TimelineDateItem date={new Date().toISOString()} text="Today" />

        {timelineElements.map((item) => {
          const isCourse = item.type === 'course'

          if (!isCourse) {
            if (item.type === ETimelinePostType.note) {
              return (
                <TimelineNoteItem
                  key={`${item.type}_${item.id}`}
                  timelineId={item.id}
                  id={item.noteId}
                  title={item.note.subject}
                  text={item.note.body}
                  date={item.note.createdAt}
                  authorId={item.note.authorId}
                  authorName={item.note.authorName}
                  status={item.status}
                />
              )
            }
            if (
              item.type === ETimelinePostType.careArticle ||
              item.type === ETimelinePostType.taskArticle
            ) {
              return (
                <TimelineArticleItem
                  key={`${item.type}_${item.id}`}
                  id={item.careArticleId}
                  title={item.careArticle.post || item.careArticle.name}
                  type={ArticleType.CareArticle}
                  careType={item.careArticle.type}
                  status={item.status}
                  bannerUrl={item.careArticle.bannerUrl}
                />
              )
            }
            if (item.type === ETimelinePostType.queries) {
              return (
                <TimelineQuestioningItem
                  key={`${item.type}_${item.id}`}
                  title={item.query.name}
                  date={item.query.createdAt}
                  id={item.queryId}
                  type="query"
                  status={item.status}
                  bannerUrl={item.query.bannerUrl}
                />
              )
            }
            return null
          }

          return (
            <TimelineCourseItem key={item.id} item={item} isForceExpanded={isAllCoursesExpanded} />
          )
        })}

        <TimelineDateItem date={userCreatedAt || ''} text="Start using app" />
      </UICollapseProvider>
    </ScrollContainer>
  )
}
