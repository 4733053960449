import { Button, Stack } from '@mui/material'
import { UICarePostArticleImg, UITaskPostArticleImg } from 'features/UI'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { ArticleType, CareArticleType, ETimelinePostStatus } from 'types'
import { getRoute } from 'utils'
import { TimelineItemCard } from '../TimelineItemCard/TimelineItemCard'
import { TimelineLine } from '../TimelineLine/TimelineLine'

export function TimelineArticleItem({
  id,
  title,
  type,
  careType,
  status,
  bannerUrl,
}: {
  id: number
  title: string
  type: ArticleType
  careType: CareArticleType
  status: ETimelinePostStatus
  quizId?: number
  bannerUrl?: string | null
}) {
  const navigate = useNavigate()

  const onClickArticle = () => {
    const route = getRoute(routes.journeyItem, {
      journeyItemType: type,
      journeyItemId: id,
    })
    navigate(route)
  }

  return (
    <Stack direction="row" spacing={2}>
      <div style={{ position: 'relative', minWidth: 30, minHeight: '100%' }}>
        {careType === CareArticleType.Care && <UICarePostArticleImg isOnTimeline />}
        {careType === CareArticleType.Task && <UITaskPostArticleImg />}
        <TimelineLine />
      </div>

      <TimelineItemCard
        title={title}
        status={status}
        bannerUrl={bannerUrl}
        onClick={onClickArticle}
        controls={
          <Button size="small" variant="contained" fullWidth onClick={onClickArticle}>
            View
          </Button>
        }
      />
    </Stack>
  )
}
