import { Stack } from '@mui/material'
import { getCareArticleById, updateCareArticle } from 'api/careArticles'
import Breadcrumbs from 'components/Breadcumbs'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import LinkToLearnLibraryModal from 'components/modals/lms/LinkToLearnLibraryModal'
import PageTitle from 'components/page/PageTitle'
import FailPlaceholder from 'components/placeholders/FailPlaceholder'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import { LinkArticleWithTags } from 'features/Tags'
import {
  UIControlBanner,
  UIControlChangeName,
  UIEntityItemArticle,
  UIEntityItemConnection,
} from 'features/UI'
import { useChangeEntityProperty } from 'hooks/useChangeEntityName'
import { useEntityDataControl } from 'hooks/useEntityDataControl'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { CareArticle, CareArticleType } from 'types'
import { getRoute, useSearchParamsObject } from 'utils'

const Labels = {
  [CareArticleType.Care]: {
    title: 'Guide Post Article View',
    routeName: 'Guide Posts',
    entityName: 'Guide Post',
  },
  [CareArticleType.Task]: {
    title: 'Action Post Article View',
    routeName: 'Action Posts',
    entityName: 'Action Post',
  },
}

const getPath = (data: CareArticle) => {
  return [
    {
      href: getRoute(routes.manageCarePosts, { type: data.type }),
      text: Labels[data.type].routeName,
    },
    {
      text: data.name,
    },
  ]
}

export function CareArticlePageEdit() {
  const { id } = useSearchParamsObject()
  const { data, setData, loading, setLoading, reloadData } = useEntityDataControl({
    id,
    loadFunc: getCareArticleById,
    warning: "Can't load the article",
  })

  const [onChangeLearnArticleLink, openLinkEdit, isOpenLinkEdit, closeLinkEdit] =
    useChangeEntityProperty({
      data,
      setLoading,
      setData,
      updateFunc: updateCareArticle,
      warning: "Can't update the learn library article link",
      field: 'learnArticleId',
    })

  const linkRemoveModal = useRemoveModalControl({
    deleteFunc: async () => {
      const response = await getCareArticleById({ id: Number(id) })
      await updateCareArticle({
        ...response.data,
        learnArticleId: null,
      })
    },
    successCallback: reloadData,
    warning: "Can't remove the link from the lesson",
  })

  const navigate = useNavigate()

  const onEditMedia = () => {
    const id = data?.id || ''
    navigate(`${routes.lessonContentEdit}?id=${id}&type=care`)
  }

  if (loading) {
    return <LoadingPlaceholder />
  }

  if (!data) {
    return (
      <FailPlaceholder
        error="Can't load data of article"
        link={getRoute(routes.manageCarePosts, { type: CareArticleType.Care })}
        linkText="Back to timeline posts"
      />
    )
  }

  const labels = Labels[data.type]

  return (
    <>
      <Stack spacing={2}>
        <PageTitle>{labels.title}</PageTitle>

        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={2} alignItems="center">
            <Breadcrumbs path={getPath(data)} />

            <UIControlChangeName<CareArticle>
              entityName="article"
              data={data}
              setData={setData}
              loading={loading}
              setLoading={setLoading}
              updateRequest={updateCareArticle}
            />
          </Stack>
        </Stack>

        <Stack spacing={3}>
          <UIControlBanner<CareArticle, 'post'>
            entityName={labels.entityName}
            nameField="post"
            data={data}
            setData={setData}
            setLoading={setLoading}
            updateRequest={updateCareArticle}
          />

          <UIEntityItemArticle
            onEdit={onEditMedia}
            text={labels.entityName}
            article={data.article}
            hasAudio={data.hasAudio}
          />

          <UIEntityItemConnection
            linkId={data.learnArticleId}
            text="Link to Learn Library Article"
            onEdit={openLinkEdit}
            onDelete={() => linkRemoveModal.openDeleteModal(0)}
            articleName={data.learnArticleName}
          />

          <LinkArticleWithTags<CareArticle>
            data={data}
            setLoading={setLoading}
            reloadData={reloadData}
            updateQuery={updateCareArticle}
          />
        </Stack>
      </Stack>

      <LinkToLearnLibraryModal
        isOpen={isOpenLinkEdit}
        handleClose={closeLinkEdit}
        value={data.learnArticleId}
        onSubmit={onChangeLearnArticleLink}
      />

      <ConfirmRemoveModal
        entityToRemove="Link to Learn Library"
        loading={linkRemoveModal.removeLoading}
        isOpen={linkRemoveModal.idToRemove !== null}
        handleConfirm={linkRemoveModal.handleConfirmRemove}
        handleClose={linkRemoveModal.closeDeleteModal}
      />
    </>
  )
}
